/**
 * Types
 */
export const Types = {
  DEFAULT: 'toasts/DEFAULT',
  INFO: 'toasts/INFO',
  SUCCESS: 'toasts/SUCCESS',
  WARNING: 'toasts/WARNING',
  ERROR: 'toasts/ERROR',
  SHOW_TOAST: 'toasts/SHOW_TOAST',
}

/**
 * Actions
 */
export const Creators = {
  buildToast: (message, type = Types.DEFAULT, options) => ({
    message,
    type,
    options,
  }),

  addToast: (message, type, options) => ({
    type: Types.SHOW_TOAST,
    toast: Creators.buildToast(message, type, options),
  }),
}
