export const Types = {
  FETCH_REQUEST: 'devices/FETCH_REQUEST',
  FETCH_SUCCESS: 'devices/FETCH_SUCCESS',
  FETCH_FAILURE: 'devices/FETCH_FAILURE',
  ADD_REQUEST: 'devices/ADD_REQUEST',
  ADD_SUCCESS: 'devices/ADD_SUCCESS',
  ADD_FAILURE: 'devices/ADD_FAILURE',
  REMOVE_REQUEST: 'devices/REMOVE_REQUEST',
  REMOVE_SUCCESS: 'devices/REMOVE_SUCCESS',
  REMOVE_FAILURE: 'devices/REMOVE_FAILURE',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
}

export default function devices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FETCH_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.ADD_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload.data],
      }
    case Types.ADD_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.REMOVE_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(
          device => device.uuid !== action.payload.data.uuid,
        ),
      }
    case Types.REMOVE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const Creators = {
  fetchDevicesRequest: (data = {}) => ({
    type: Types.FETCH_REQUEST,
    payload: { data },
  }),
  addDeviceRequest: data => ({
    type: Types.ADD_REQUEST,
    payload: { data },
  }),
  removeDeviceRequest: data => ({
    type: Types.REMOVE_REQUEST,
    payload: { data },
  }),
}
