import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { Types as AuthTypes } from '../ducks/auth'
import { Types as UnitsTypes } from '../ducks/units'
import { Types as DevicesTypes } from '../ducks/devices'
import { Types as SelectCompaniesTypes } from '../ducks/selectCompanies'
import { Types as SelectUnitsTypes } from '../ducks/selectUnits'
import { Types as SelectDevicesTypes } from '../ducks/selectDevices'
import { Types as PointsTypes } from '../ducks/tagPoints'
import { Types as CompaniesTypes } from '../ducks/companies'
import { Types as CorrelationTypes } from '../ducks/correlation'
import { Types as GroupingTypes } from '../ducks/grouping'
import { Types as RegionsTypes } from '../ducks/regions'
import { Types as BenchmarkingTypes } from '../ducks/benchmarking'
import { Types as ReportsMonthlyTypes } from '../ducks/reportsMonthly'
import { Types as ReportsWeeklyTypes } from '../ducks/reportsWeekly'

import { login, signup } from './auth'
import { rehydrate } from './persist'
import { addUnit, removeUnit } from './units'
import { fetchDevicesRequest, addDeviceRequest, removeDeviceRequest } from './devices'
import { fetchSelectCompaniesRequest } from './selectCompanies'
import { fetchAllCompaniesRequest } from './companies'
import { fetchSelectUnitsRequest } from './selectUnits'
import { fetchSelectDevicesRequest } from './selectDevices'
import {
  fetchTagPointsRequest,
  updatePointRequest,
  savePointNoteRequest,
  savePointValueRequest,
} from './tagPoints'
import { fetchCorrelationRequest } from './correlation'
import { fetchGroupingRequest } from './grouping'
import {
  fetchAllRegionsRequest,
  addRegionRequest,
  addUnitIntoRegionRequest,
  removeUnitIntoRegionRequest,
  removeRegionRequest,
  fetchUnitsInRegionRequest,
} from './regions'
import {
  BenchmarkingFetchAllTagsRequest,
  BenchmarkingFetchAllRegionsRequest,
  BenchmarkingFetchAllAttributesRequest,
  BenchmarkingFetchDataRequest,
} from './benchmarking'

import {
  ReportsMonthlyFetchAllTagsRequest,
  ReportsMonthlyFetchAllAreasRequest,
  ReportsMonthlyAddAreaRequest,
  ReportsMonthlyRemoveAreaRequest,
  ReportsMonthlyFetchWidgetByAreaRequest,
  ReportsMonthlyFetchAllWidgetRequest,
  ReportsMonthlyFetchAllRegionsRequest,
  ReportsMonthlyFetchAllUnitsRequest,
  ReportsMonthlyAddMultiAreasRequest,
} from './reportsMonthly'

import {
  ReportsWeeklyFetchAllTagsRequest,
  ReportsWeeklyFetchAllAreasRequest,
  ReportsWeeklyAddAreaRequest,
  ReportsWeeklyRemoveAreaRequest,
  ReportsWeeklyFetchWidgetByAreaRequest,
  ReportsWeeklyFetchAllRegionsRequest,
  ReportsWeeklyFetchAllUnitsRequest,
  ReportsWeeklyAddWidgetRequest,
  ReportsWeeklyRemoveWidgetRequest,
  ReportsWeeklyAddMultiAreasRequest,
} from './reportsWeekly'

export default function* rootSaga() {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.SIGNUP_REQUEST, signup),
    takeLatest(UnitsTypes.ADD_REQUEST, addUnit),
    takeLatest(UnitsTypes.REMOVE_REQUEST, removeUnit),
    takeLatest(DevicesTypes.FETCH_REQUEST, fetchDevicesRequest),
    takeLatest(DevicesTypes.ADD_REQUEST, addDeviceRequest),
    takeLatest(DevicesTypes.REMOVE_REQUEST, removeDeviceRequest),
    takeLatest(CompaniesTypes.FETCH_ALL_COMPANIES_REQUEST, fetchAllCompaniesRequest),
    takeLatest(SelectCompaniesTypes.FETCH_REQUEST, fetchSelectCompaniesRequest),
    takeLatest(SelectUnitsTypes.FETCH_REQUEST, fetchSelectUnitsRequest),
    takeLatest(SelectDevicesTypes.FETCH_REQUEST, fetchSelectDevicesRequest),

    takeLatest(PointsTypes.FETCH_REQUEST, fetchTagPointsRequest),
    takeEvery(PointsTypes.UPDATE_POINT_REQUEST, updatePointRequest),
    takeEvery(PointsTypes.SAVE_POINT_NOTE_REQUEST, savePointNoteRequest),

    takeEvery(PointsTypes.SAVE_POINT_VALUE_REQUEST, savePointValueRequest),

    takeLatest(CorrelationTypes.FETCH_REQUEST, fetchCorrelationRequest),
    takeLatest(GroupingTypes.FETCH_REQUEST, fetchGroupingRequest),

    takeLatest(RegionsTypes.FETCH_ALL_REQUEST, fetchAllRegionsRequest),
    takeLatest(RegionsTypes.ADD_REQUEST, addRegionRequest),
    takeLatest(RegionsTypes.REMOVE_REQUEST, removeRegionRequest),
    takeLatest(RegionsTypes.ADD_UNIT_IN_REGION_REQUEST, addUnitIntoRegionRequest),
    takeLatest(RegionsTypes.REMOVE_UNIT_IN_REGION_REQUEST, removeUnitIntoRegionRequest),
    takeLatest(RegionsTypes.FETCH_UNITS_IN_REGION_REQUEST, fetchUnitsInRegionRequest),

    takeLatest(
      BenchmarkingTypes.BENCHMARKING_FETCH_ALL_TAGS_REQUEST,
      BenchmarkingFetchAllTagsRequest,
    ),
    takeLatest(
      BenchmarkingTypes.BENCHMARKING_FETCH_ALL_REGIONS_REQUEST,
      BenchmarkingFetchAllRegionsRequest,
    ),
    takeLatest(
      BenchmarkingTypes.BENCHMARKING_FETCH_ALL_ATTRIBUTES_REQUEST,
      BenchmarkingFetchAllAttributesRequest,
    ),
    takeLatest(BenchmarkingTypes.BENCHMARKING_FETCH_DATA_REQUEST, BenchmarkingFetchDataRequest),

    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_ALL_TAGS_REQUEST,
      ReportsMonthlyFetchAllTagsRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_ALL_AREAS_REQUEST,
      ReportsMonthlyFetchAllAreasRequest,
    ),
    takeLatest(ReportsMonthlyTypes.REPORTS_MONTHLY_ADD_AREA_REQUEST, ReportsMonthlyAddAreaRequest),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_ADD_MULTI_AREAS_REQUEST,
      ReportsMonthlyAddMultiAreasRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_REMOVE_AREA_REQUEST,
      ReportsMonthlyRemoveAreaRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_REQUEST,
      ReportsMonthlyFetchWidgetByAreaRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_ALL_WIDGET_REQUEST,
      ReportsMonthlyFetchAllWidgetRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_ALL_REGIONS_REQUEST,
      ReportsMonthlyFetchAllRegionsRequest,
    ),
    takeLatest(
      ReportsMonthlyTypes.REPORTS_MONTHLY_FETCH_ALL_UNITS_REQUEST,
      ReportsMonthlyFetchAllUnitsRequest,
    ),

    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_FETCH_ALL_TAGS_REQUEST,
      ReportsWeeklyFetchAllTagsRequest,
    ),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_FETCH_ALL_AREAS_REQUEST,
      ReportsWeeklyFetchAllAreasRequest,
    ),
    takeLatest(ReportsWeeklyTypes.REPORTS_WEEKLY_ADD_AREA_REQUEST, ReportsWeeklyAddAreaRequest),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_ADD_MULTI_AREAS_REQUEST,
      ReportsWeeklyAddMultiAreasRequest,
    ),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_REMOVE_AREA_REQUEST,
      ReportsWeeklyRemoveAreaRequest,
    ),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_REQUEST,
      ReportsWeeklyFetchWidgetByAreaRequest,
    ),
    takeLatest(ReportsWeeklyTypes.REPORTS_WEEKLY_ADD_WIDGET_REQUEST, ReportsWeeklyAddWidgetRequest),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_REMOVE_WIDGET_REQUEST,
      ReportsWeeklyRemoveWidgetRequest,
    ),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_FETCH_ALL_REGIONS_REQUEST,
      ReportsWeeklyFetchAllRegionsRequest,
    ),
    takeLatest(
      ReportsWeeklyTypes.REPORTS_WEEKLY_FETCH_ALL_UNITS_REQUEST,
      ReportsWeeklyFetchAllUnitsRequest,
    ),

    takeLatest(REHYDRATE, rehydrate),
  ])
}
