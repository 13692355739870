export default {
  themify: {
    background: '#fff',
    color: '#1d2124',
  },
  borderColor: '#343a40',
  sidebar: {
    background: '#F7F9FB',
    navMain: {
      background: '#FFFFFF',
      navMainHeading: {
        color: '#DAE0EC',
      },
      navMainLink: {
        classActive: {
          background: '#DFF0FD',
          navMainLinkIcon: {
            color: '#a62f2b',
          },
        },
        hover: {
          background: '#DFF0FD',
          navMainLinkIcon: {
            color: '#a62f2b',
          },
        },
      },
    },
  },
  pageHeader: {
    background: '#A62F2B',
    color: '#FFFFFF',
  },
  mainContainer: {
    color: '#FFFFFF',
  },
  pageFooter: {
    background: '#F7F9FB',
    color: '#000',
    colorLink: '',
  },
  block: {
    background: '#fff',
    color: '#495057',
    header: {
      background: '#A62F2B',
      color: '#fff',
      colorNoBackground: '#495057',
    },
    content: {
      background: '#fff',
      color: '#495057',
      footer: {
        background: '#f9fafc',
      },
    },
    themify: {
      background: '#fff',
      color: '#000',
      boxShadow: '0 2px 6px rgba(230,235,244,0.4);',
    },
    fxShadow: {
      boxShadow: '0 0 2.25rem #d8dfed',
    },
    btnOption: {
      color: '#A62F2B',
      hoverColor: '#2a8bf9',
    },
    dropdownMenu: {
      background: '#fff',
      color: '',
      hoverBackground: '#f9fafc',
      hoverColor: '',
    },
    BgGdPrimary: {
      boxShadow: '0 2px 6px rgba(230, 235, 244, 0.4);',
    },
    linkPopHover: {
      boxShadow: '0 0.5rem 2rem #d8dfed;',
    },
  },
  btn: {
    light: {
      background: '',
      color: '',
      borderColor: '',
    },
  },
  navTabs: {
    background: '#f9fafc',
    color: '',
    item: {
      color: '#495057',
      background: '#f9fafc',
      active: {
        color: '#495057',
        background: '#fff',
      },
    },
  },
  table: {
    color: '#495057',
    background: '#e6ebf4',
    th: {
      color: '#495057',
      background: '#e6ebf4',
      span: {
        color: '#6c757d',
      },
      border: {
        color: '#e6ebf4',
      },
    },
    tbody: {
      background: '#fff',
      color: '#495057',
      active: {
        background: '#eff2f8',
      },
      trSuccess: {
        background: '#dceacd',
      },
      trHover: {
        background: '#eff2f8',
      },
    },
  },
}
