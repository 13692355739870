import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

import { Store } from 'store'

const { profile } = Store.getState()

const intialLanguage = profile.language
  ? profile.language
  : navigator.language || navigator.userLanguage || 'pt-BR'

i18n.use(reactI18nextModule).init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: intialLanguage,
  fallbackLng: 'en-US',
  // Using simple hardcoded resources for simple example
  resources: {
    'pt-BR': {
      displayName: 'Português',
      translation: {
        INPUTS: {
          PLACEHOLDERS: {
            BOARD_DESCRIPTION:
              'Descreva o que poderá ser visualizado nesse quadro',
            BOARD_NAME: 'Título do quadro',
            COMPANY_NAME: 'Nome da Empresa',
            EMAIL: 'Email',
            IMAGE: 'Selecione uma image',
            NAME: 'Nome',
            NEW_PASSWORD: 'Nova Senha',
            PASSWORD: 'Senha',
            REGION_NAME: 'Nome da região',
            SEARCH: 'Pesquisar',
            SELECT_A_COMPANY: 'Selecione uma empresa',
            SELECT_A_PERIOD: 'Selecione um período',
            SELECT_A_TAG: 'Selecione uma tag',
            SELECT_A_UNIT: 'Selecione uma unidade',
            SELECT_ATTRIBUTE: 'Selecione um atributo',
            SELECT_ATTRIBUTES: 'Selecione atributos',
            SELECT: 'Selecione',
            TAG_NAME: 'Nome da Tag',
            TEAM_NAME: 'Nome da Equipe',
            VALUE: 'Valor',
            ATTRIBUTE_NAME: 'Nome do Atributo',
            ATTRIBUTE_VALUE: 'Valor do Atributo',
          },
          TERMS:
            'Eu concordo com o Termos de Serviço e a Política de Privacidade da Fermentec',
        },
        BUTTONS: {
          LOADING: 'Aguarde...',
          ACCEPT: 'Aceitar',
          ADD_NEW_AREA: 'Adicionar nova área',
          ADD_NEW_REPORT: 'Adicionar Relatório',
          ADD_OBSERVATION: 'Adicionar Observação',
          ADD: 'Adicionar',
          BACK_TO_LIST: 'Voltar para Lista',
          BACK: 'Voltar',
          CANCEL: 'Cancelar',
          CHANGE_PASSWORD: 'Alterar Senha',
          CLOSE: 'Fechar',
          CREATE_COMPANY: 'Criar Empresa',
          CREATE_REGION: 'Criar Região',
          CREATE_TEAM: 'Criar Equipe',
          CREATE_UNIT: 'Criar Unidade',
          DELETE_AREA: 'Remover área',
          DELETE_COMPANY: 'Excluir Empresa',
          DELETE_UNIT: 'Excluir Unidade',
          DELETE_WIDGET: 'Remover widget',
          DELETE: 'Excluir',
          EDIT: 'Editar',
          EXPORT: 'Exportar',
          EXPORT_ALL: 'Exportar todas as áreas',
          FORGOT_PASSWORD: 'Esqueci a senha',
          INSERT: 'Inserir',
          LOGOUT: 'Logout',
          MANUAL: 'Manual',
          NEW_ACCOUNT: 'Nova conta',
          NEW_BOARD: 'Adicionar Quadro',
          NEW_DEVICE: 'Novo Dispositivo',
          NEW_TAG: 'Adicionar Tag',
          NEW_UNIT: 'Adicionar Unidade',
          NEW_USER: 'Adicionar Usuário',
          NEW_WIDGET: 'Adicionar Widget',
          NO: 'Não',
          READ_TERMS: 'Termos & Privacidade',
          RECOVER_PASSWORD: 'Recuperar a senha',
          REMOVE_UNIT_THIS_REGION: 'Remover unidade desta região',
          RESET: 'Limpar',
          SAVE: 'Salvar',
          SIGN_IN: 'Login',
          SIGN_OUT: 'Sair',
          SIGN_UP: 'Inscrever-se',
          UPLOAD: 'Upload',
          YES: 'Sim',
          CREATE_ATTRIBUTE: 'Criar Atributo',
          ATTRIBUTES: 'Atributos',
          DELETE_ATTRIBUTE: 'Excluir Atributo',
          CREATE_DEVICE: 'Criar Dispositivo',
          DELETE_DEVICE: 'Excluir Dispositivo',
          DISABLE: 'Desativar',
          ACTIVE: 'Ativar',
          DOWNLOAD_MODEL: 'Baixar Modelo',
        },
        ERRORS: {
          WRONG_PASSWORD: 'Senha incorreta',
          USER_ALREADY_EXIST: 'Usuário já existe',
          USER_NOT_FOUND: 'Usuário não encontrado',
          EMAIL_ALREADY_EXISTS: 'Email já cadastrado',
          NAME_REQUIRED: 'Nome é obrigatório',
          EMAIL_REQUIRED: 'Email é obrigatório',
          EMAIL_INVALID: 'Email inválido',
          COMPANY_REQUIRED: 'Empresa é obrigatório',
          PASSWORD_REQUIRED: 'Senha é obrigatório',
          CONFIRM_PASSWORD_REQUIRED: 'Confirmação da senha é obrigatória',
          PASSWORD_CONFIRMATION_NOT_MATCH:
            'Confirmação de senha e senha não coincidem',
          PASSWORD_MIN_LENGTH_6: 'Este campo deve conter mais de 6 caracteres',
          AUTH_FAILED: 'Falha na autenticação',
          AUTH_FAILED_TOKEN: 'Falha na autenticação',
          INCORRECT_PASSWORD: 'Email ou senha está incorreto',
          UNKNOWN_ERROR:
            'Houve um problema de comunicação com os servidores, por favor, tente novamente mais tarde',
          INVALID: 'O token é inválido',
          LANGUAGE_REQUIRED: 'Linguagem é obrigatória',
          DATA_NOT_FOUND: 'Dados não encontrados',
          VALUE_REQUIRED: 'Valor é obrigatório',
          ATTRIBUTE_REQUIRED: 'Atributo é obrigatório',
          REPORT_REQUIRED: 'Relatório é obrigatório',
          CODE_REQUIRED: 'Código é obrigatório',
          TYPE_REQUIRED: 'Tipo é obrigatório',
          UNIT_REQUIRED: 'Unidade é obrigatório',
          UNIT_DOES_NOT_CONTAIN_ATTRIBUTES: 'Unidade não contém atributos',
          USER_IS_NOT_ASSIGNED_TO_A_UNIT:
            'Usuário não está atribuído a uma unidade',
          ORDER_REQUIRED: 'Ordem é obrigatório',
        },
        WARNINGS: {
          USER_ALREADY_ADDED: 'Usuário já incluso',
        },
        SUCCESS: {
          DATA_HAS_BEEN_SAVED: 'Os dados foram salvos',
          DELETED: 'Excluído',
          EMAIL_SENT: 'Email enviado',
          PASSWORD_UPDATED: 'Senha alterada',
        },
        NOTIFICATIONS: {},
        LABELS: {
          OPTIONS: 'Opções',
          OBSERVATION: 'Observação',
          NOT_FOUND_TITLE:
            'Oops.. Você acabou de encontrar uma página de erro.',
          NOT_FOUND_MESSAGE:
            'Sentimos muito, mas parece que sua requisição não foi executada.',
          LOGIN_SIGNIN: 'Iniciar Sessão',
          LOGIN_SIGNUP: 'Inscrever-se',
          LOGIN_WELCOME_MESSAGE: 'Benchmarking',
          COMING_SOON: 'Em breve',
          REGISTER_NEW_PASSWORD: 'Cadastrar nova senha',
          LOST_PASSWORD: 'Esqueci a senha',
          SHOW: 'Mostrar',
          HIDE: 'Esconder',
          BACK: 'Voltar',
          PREV: 'Anterior',
          NEXT: 'Próximo',
          ENTER: 'Entrar',
          UNITS: 'Unidades',
          ANALYTICS: 'Análises',
          SETTINGS: 'Ajustes',
          GLOBAL_SETTINGS: 'Ajustes Globais',
          GET_STARTED_WELCOME_MESSAGE: 'Obrigado por se inscrever na Fermentec',
          TAGS: 'Tags',
          TAG: 'Tag',
          DEVICES: 'Dispositivos',
          DEVICE: 'Dispositivo',
          PERIOD: 'Período',
          CHART: 'Gráfico',
          LIST: 'Lista',
          SUM: 'Soma',
          AVERAGE: 'Média',
          QUANTITY: 'Quantidade',
          MINIMUM: 'Mínimo',
          MAXIMUM: 'Máximo',
          STANDARD_DEVIATION: 'Desvio Padrão',
          VARIANCE: 'Variância',
          MEDIAN: 'Mediana',
          DIFFERENCE: 'Diferença',
          DATE: 'Data',
          INDICATOR: 'Indicador',
          VALUE: 'Valor',
          USERS: 'Usuários',
          NEW_USER: 'Novo Usuário',
          USERS_LIST: 'Lista de Usuários',
          NEW_UNIT: 'Nova Unidade',
          UNITS_LIST: 'Lista de Unidades',
          NEW_TAG: 'Nova Tag',
          TAGS_LIST: 'Lista de Tags',
          PREVIEW: 'Pré-visualização',
          NAME: 'Nome',
          EMAIL: 'Email',
          PASSWORD: 'Senha',
          CONFIRM_PASSWORD: 'Confirmar Senha',
          LANGUAGE: 'Idioma',
          ACTIONS: 'Ações',
          UNIT_NAME: 'Nome da Unidade',
          DASHBOARDS: 'Dashboards',
          EDIT_USER: 'Editar Usuário',
          EDIT_UNIT: 'Editar Unidade',
          ACTION_CONFIRMATION: 'Tem certeza',
          ACTION_CONFIRMATION_TEXT: 'Você não poderá reverter isso',
          NEW_DASHBOARD: 'Novo Quadro',
          DESCRIPTION: 'Descrição',
          BACKGROUND_IMAGE: 'Imagem de Fundo',
          CONFIRM_DELETE: 'Confirmar exclusão',
          DEVICES_LIST: 'Lista de Dispositivos',
          TITLE: 'Título',
          GENERAL: 'Geral',
          NEW_WIDGET: 'Novo Widget',
          NEW_DEVICE: 'Novo Dispositivo',
          EDIT_DEVICE: 'Editar Dispositivo',
          PROCESS_ANALYTICS: 'Análise de Processo',
          PERFORMANCE_ANALYTICS: 'Análise de Performace',
          EVENT_MANAGER: 'Gestão de Eventos',
          EVENTS: 'Eventos',
          STATISTICAL_DATA: 'Dados Estatísticos',
          STATISTICAL_PROCESS_CONTROL: 'CEP',
          STATISTICAL_PROCESS_CONTROL_EXTENDED:
            'Controle Estatístico de Processo',
          COMPANY_NAME: 'Empresa',
          EDIT_PROFILE: 'Editar Perfil',
          CHANGE_PASSWORD: 'Alterar senha',
          MANAGE_UNITS: 'Gerenciar unidades',
          OLD_PASSWORD: 'Senha antiga',
          NEW_PASSWORD: 'Nova senha',
          CONFIRM_NEW_PASSWORD: 'Confirme a nova senha',
          DATA_VALIDATION: 'Validação de Dados',
          REPORTS: 'Relatórios',
          POINTS: 'Pontos',
          WEEKLY: 'Semanal',
          MONTHLY: 'Mensal',
          GROUPING: 'Agrupamento',
          CORRELATION: 'Correlação',
          BENCHMARKING: 'Benchmarking',
          REGIONS: 'Regiões',
          KEEP_ME_LOGGED: 'Manter-me conectado',
          COMPANY: 'Empresa',
          DAY: 'Dia',
          WEEK: 'Semana',
          MONTH: 'Mês',
          HARVEST: 'Safra',
          YEAR: 'Ano',
          LOADING: 'Carregando',
          GROUP_BY: 'Agrupar por',
          NOTE: 'Observação',
          FILTER: 'Filtro',
          TODAY: 'Hoje',
          YESTERDAY: 'Ontem',
          LAST_7_DAYS: 'Últimos 7 dias',
          LAST_30_DAYS: 'Últimos 30 dias',
          LAST_WEEK: 'Última semana',
          LAST_MONTH: 'Último mês',
          THIS_MONTH: 'Este mês',
          TO_DATES: 'à',
          INCLINATION: 'Inclinação',
          RESIDUAL_ERROR: 'Erro Residual',
          COEFFICIENT: 'Coeficiente',
          STANDARD_ERROR: 'Erro padrão',
          INTERCEPTION: 'Interceptação',
          P_VALUE: 'P-Valor',
          HOUR: 'Hora',
          SELECT_TWO_TAGS: 'Selecione duas tags',
          ATTRIBUTES: 'Atributos',
          UNIT: 'Unidade',
          SELECT_ATTRIBUTES: 'Selecione atributos',
          CREATE: 'Criar',
          ADD_UNIT: 'Adicionar Unidade',
          FETCHING_DATA: 'Carregando dados',
          REGION_AVERAGE: 'Média da região',
          AVERAGE_OVERALL: 'Média total',
          ADD_AREA: 'Adicionar área',
          REGION: 'Região',
          R_VALUE: 'R-Valor',
          EXPORT: 'Exportar',
          EXPORT_ALL: 'Exportar todas as áreas',
          TYPE: 'Tipo',
          IMPORT: 'Importar',
          ADD_USER: 'Adicionar usuário',
          SPECIALIST: 'Especialista',
          TEAMS: 'Equipes',
          REPORT: 'Relatório',
          COMPANIES: 'Empresas',
          SELECT: 'Selecione',
          BOTH: 'Ambos',
          HAS_CONTRACT: 'Possui contrato',
          UNAUDITED: 'Não auditado',
          SHOW_NAME: 'Exibir Nome',
          SHOW_NAMES: 'Mostrar nomes',
          SHOW_LABELS: 'Mostrar valores',
          SEARCH_FOR_TAG_NAME: 'Pesquise pelo nome da tag',
          ATTRIBUTE_VALUE: 'Valor do Atributo',
          CODE: 'Código',
          HIGHER_BETTER: 'Maior Melhor',
          STATISTICAL_ERROR: 'Erro Estatístico',
          SENDING: 'Enviando',
          ACCUMULATED_WEEK: 'Semana Acumulada',
          AUTHENTICATING: 'Autenticando',
          RELEASED_MONTHS: 'Meses Liberados',
          ORDER: 'Ordem',
          DEACTIVE_A: 'Desativada',
          ACTIVE_A: 'Ativa',
          STATUS_HISTORIC: 'Histórico de Status',
          STATUS: 'Status',
        },
        CHARTS: {
          TITLES: {
            BENCHMARKING_MONTHLY: 'Benchmarking Mensal',
            AVERAGE_FERMENTEC_CUSTOMERS: 'Média Clientes Fermentec',
          },
        },
        LINKS: {
          GO_TO_PROFILE_EDIT: 'Ir para edição do perfil',
        },
      },
    },
    'en-US': {
      displayName: 'English',
      translation: {
        INPUTS: {
          PLACEHOLDERS: {
            BOARD_DESCRIPTION: 'Describe what can be seen in the board',
            BOARD_NAME: 'Title of the board',
            COMPANY_NAME: 'Company Name',
            EMAIL: 'Email',
            IMAGE: 'Select an image',
            NAME: 'Name',
            NEW_PASSWORD: 'New Password',
            PASSWORD: 'Password',
            REGION_NAME: 'Region Name',
            SEARCH: 'Search',
            SELECT_A_COMPANY: 'Select a company',
            SELECT_A_PERIOD: 'Select a period',
            SELECT_A_TAG: 'Select a tag',
            SELECT_A_UNIT: 'Select an unit',
            SELECT_ATTRIBUTE: 'Select an attribute',
            SELECT_ATTRIBUTES: 'Select attributes',
            SELECT: 'Select',
            TAG_NAME: 'Tag Name',
            TEAM_NAME: 'Team Name',
            VALUE: 'Value',
            ATTRIBUTE_NAME: 'Attribute Name',
            ATTRIBUTE_VALUE: 'Attribute Value',
          },
          TERMS: "I agree to Fermentec's Terms of Service and Privacy Policy",
        },
        BUTTONS: {
          LOADING: 'Wait...',
          ACCEPT: 'Accept',
          ADD_NEW_AREA: 'Add new area',
          ADD_NEW_REPORT: 'Add new report',
          ADD_OBSERVATION: 'Add Observation',
          ADD: 'Add',
          BACK_TO_LIST: 'Back to List',
          BACK: 'Back',
          CANCEL: 'Cancel',
          CHANGE_PASSWORD: 'Change Password',
          CLOSE: 'Close',
          CREATE_COMPANY: 'Create Company',
          CREATE_REGION: 'Create Region',
          CREATE_TEAM: 'Create Team',
          CREATE_UNIT: 'Create Unit',
          DELETE_AREA: 'Delete area',
          DELETE_COMPANY: 'Delete Company',
          DELETE_UNIT: 'Delete Unit',
          DELETE_WIDGET: 'Delete widget',
          DELETE: 'Delete',
          EDIT: 'Edit',
          EXPORT: 'Export',
          EXPORT_ALL: 'Export All',
          FORGOT_PASSWORD: 'Forgot the password',
          INSERT: 'Insert',
          LOGOUT: 'Logout',
          MANUAL: 'Manual',
          NEW_ACCOUNT: 'New Account',
          NEW_BOARD: 'New Board',
          NEW_DEVICE: 'New Device',
          NEW_TAG: 'New Tag',
          NEW_UNIT: 'New Unit',
          NEW_USER: 'New User',
          NEW_WIDGET: 'New Widget',
          NO: 'No',
          READ_TERMS: 'Terms & Privacy',
          RECOVER_PASSWORD: 'Recover password',
          REMOVE_UNIT_THIS_REGION: 'Remove unit this region',
          RESET: 'Reset',
          SAVE: 'Save',
          SIGN_IN: 'Login',
          SIGN_OUT: 'Sign out',
          SIGN_UP: 'Sign up',
          UPLOAD: 'Upload',
          YES: 'Yes',
          CREATE_ATTRIBUTE: 'Create Attribute',
          ATTRIBUTES: 'Attributes',
          DELETE_ATTRIBUTE: 'Delete Attribute',
          CREATE_DEVICE: 'Create device',
          DELETE_DEVICE: 'Delete Device',
          DISABLE: 'Disable',
          ACTIVE: 'Active',
          DOWNLOAD_MODEL: 'Download Model',
        },
        WARNINGS: {
          USER_ALREADY_ADDED: 'User already added',
        },
        ERRORS: {
          WRONG_PASSWORD: 'Wrong password',
          USER_ALREADY_EXIST: 'User already exists',
          USER_NOT_FOUND: 'User not found',
          EMAIL_ALREADY_EXISTS: 'E-mail already registered',
          NAME_REQUIRED: 'Name is required',
          EMAIL_REQUIRED: 'Email is required',
          EMAIL_INVALID: 'Email is invalid',
          COMPANY_REQUIRED: 'Company is required',
          PASSWORD_REQUIRED: 'Password is required',
          CONFIRM_PASSWORD_REQUIRED: 'Password confirmation is required',
          PASSWORD_CONFIRMATION_NOT_MATCH:
            'Password and password confirmation do not match.',
          PASSWORD_MIN_LENGTH_6:
            'This field must contain more than 6 characters',
          AUTH_FAILED: 'Authentication failed',
          AUTH_FAILED_TOKEN: 'Authentication failed',
          INCORRECT_PASSWORD: 'Email or password is incorrect',
          UNKNOWN_ERROR: 'An error occurred, please try again later',
          INVALID: 'The token is invalid',
          LANGUAGE_REQUIRED: 'Language is required',
          DATA_NOT_FOUND: 'Data not found',
          VALUE_REQUIRED: 'Value is required',
          ATTRIBUTE_REQUIRED: 'Attribute is required',
          REPORT_REQUIRED: 'Report is required',
          CODE_REQUIRED: 'Code is required',
          TYPE_REQUIRED: 'Type is required',
          UNIT_REQUIRED: 'Unit is required',
          UNIT_DOES_NOT_CONTAIN_ATTRIBUTES: 'Unit does not contain attributes',
          USER_IS_NOT_ASSIGNED_TO_A_UNIT: 'User is not assigned to a unit',
          ORDER_REQUIRED: 'Order is required',
        },
        SUCCESS: {
          DATA_HAS_BEEN_SAVED: 'Data has been saved',
          EMAIL_SENT: 'Email sent',
          PASSWORD_UPDATED: 'Password changed',
        },
        NOTIFICATIONS: {},
        LABELS: {
          OPTIONS: 'Options',
          OBSERVATION: 'Observation',
          NOT_FOUND_TITLE: 'Oops.. You just found an error page.',
          NOT_FOUND_MESSAGE:
            'We are sorry but your request contains bad syntax and cannot be fulfilled.',
          LOGIN_SIGNIN: 'Sign In',
          LOGIN_SIGNUP: 'Sign Up',
          LOGIN_WELCOME_MESSAGE: 'Benchmarking',
          COMING_SOON: 'Coming Soon',
          REGISTER_NEW_PASSWORD: 'Enter new password',
          LOST_PASSWORD: 'Forgot the password',
          SHOW: 'Show',
          HIDE: 'Hide',
          BACK: 'Go Back',
          PREV: 'Previous',
          NEXT: 'Next',
          ENTER: 'Enter',
          UNITS: 'Units',
          ANALYTICS: 'Analytics',
          SETTINGS: 'Settings',
          GLOBAL_SETTINGS: 'Global Settings',
          GET_STARTED_WELCOME_MESSAGE: 'Thank you for signing up for Fermentec',
          TAGS: 'Tags',
          TAG: 'Tag',
          DEVICES: 'Devices',
          DEVICE: 'Device',
          PERIOD: 'Period',
          CHART: 'Chart',
          LIST: 'List',
          SUM: 'Sum',
          AVERAGE: 'Average',
          QUANTITY: 'Quantity',
          MINIMUM: 'Minimum',
          MAXIMUM: 'Maximum',
          STANDARD_DEVIATION: 'Standard Deviation',
          VARIANCE: 'Variance',
          MEDIAN: 'Median',
          DIFFERENCE: 'Difference',
          DATE: 'Date',
          INDICATOR: 'Indicator',
          VALUE: 'Value',
          USERS: 'Users',
          NEW_USER: 'New User',
          USERS_LIST: 'Users List',
          NEW_UNIT: 'New Unit',
          UNITS_LIST: 'Units List',
          NEW_TAG: 'New Tag',
          TAGS_LIST: 'Tags List',
          PREVIEW: 'Preview',
          NAME: 'Name',
          EMAIL: 'Email',
          PASSWORD: 'Password',
          CONFIRM_PASSWORD: 'Confirm Password',
          LANGUAGE: 'Language',
          ACTIONS: 'Actions',
          UNIT_NAME: 'unit Name',
          DASHBOARDS: 'Dashboards',
          EDIT_USER: 'Edit User',
          EDIT_UNIT: 'Edit Unit',
          ACTION_CONFIRMATION: 'Are you sure?',
          ACTION_CONFIRMATION_TEXT: 'This can`t be undone',
          NEW_DASHBOARD: 'New Dashboard',
          DESCRIPTION: 'Description',
          BACKGROUND_IMAGE: 'Background Image',
          CONFIRM_DELETE: 'Confirm delete',
          DEVICES_LIST: 'Device List',
          TITLE: 'Title',
          GENERAL: 'General',
          NEW_WIDGET: 'New Widget',
          NEW_DEVICE: 'New Device',
          EDIT_DEVICE: 'Edit Device',
          PROCESS_ANALYTICS: 'Process Analytics',
          PERFORMANCE_ANALYTICS: 'Performance Analytics',
          EVENT_MANAGER: 'Event Manager',
          EVENTS: 'Events',
          STATISTICAL_DATA: 'Statistical Data',
          STATISTICAL_PROCESS_CONTROL: 'SPC',
          STATISTICAL_PROCESS_CONTROL_EXTENDED: 'Statistical Process Control',
          COMPANY_NAME: 'Company Name',
          EDIT_PROFILE: 'Edit profile',
          CHANGE_PASSWORD: 'Change password',
          MANAGE_UNITS: 'Manage units',
          OLD_PASSWORD: 'Old password',
          NEW_PASSWORD: 'New password',
          CONFIRM_NEW_PASSWORD: 'Confirm new password',
          DATA_VALIDATION: 'Data Validation',
          REPORTS: 'Reports',
          POINTS: 'Points',
          WEEKLY: 'Weekly',
          MONTHLY: 'Monthly',
          GROUPING: 'Grouping',
          CORRELATION: 'Correlation',
          BENCHMARKING: 'Benchmarking',
          REGIONS: 'Regions',
          KEEP_ME_LOGGED: 'Keep me logged',
          COMPANY: 'Company',
          DAY: 'Day',
          WEEK: 'Week',
          MONTH: 'Month',
          HARVEST: 'Harvest',
          YEAR: 'Year',
          LOADING: 'Loading',
          GROUP_BY: 'Group by',
          NOTE: 'Note',
          FILTER: 'Filter',
          TODAY: 'Today',
          YESTERDAY: 'Yesterday',
          LAST_7_DAYS: 'Last 7 days',
          LAST_30_DAYS: 'Last 30 days',
          LAST_WEEK: 'Last week',
          LAST_MONTH: 'Last month',
          THIS_MONTH: 'This month',
          TO_DATES: 'to',
          INCLINATION: 'Inclination',
          RESIDUAL_ERROR: 'Residual Error',
          COEFFICIENT: 'Coefficient',
          STANDARD_ERROR: 'Standard Error',
          INTERCEPTION: 'Interception',
          P_VALUE: 'P-Value',
          HOUR: 'Hour',
          SELECT_TWO_TAGS: 'Select two tags',
          ATTRIBUTES: 'Attributes',
          UNIT: 'Unit',
          SELECT_ATTRIBUTES: 'Select attributes',
          CREATE: 'Create',
          ADD_UNIT: 'Add Unit',
          FETCHING_DATA: 'Fetching data',
          ADD_AREA: 'Add area',
          REGION: 'Region',
          R_VALUE: 'R-Value',
          EXPORT: 'Export',
          EXPORT_ALL: 'Export All',
          TYPE: 'Type',
          IMPORT: 'Import',
          ADD_USER: 'Add user',
          SPECIALIST: 'Specialist',
          TEAMS: 'Teams',
          REPORT: 'Report',
          COMPANIES: 'Companies',
          SELECT: 'Select',
          BOTH: 'Both',
          HAS_CONTRACT: 'Has Contract',
          UNAUDITED: 'Unaudited',
          SHOW_NAME: 'Show Name',
          SHOW_NAMES: 'Show names',
          SHOW_LABELS: 'Show values',
          SEARCH_FOR_TAG_NAME: 'Search for tag name',
          ATTRIBUTE_VALUE: 'Attribute Value',
          CODE: 'Code',
          HIGHER_BETTER: 'High Better',
          STATISTICAL_ERROR: 'Statistical Erro',
          SENDING: 'Sending',
          ACCUMULATED_WEEK: 'Accumulated Week',
          AUTHENTICATING: 'Authenticating',
          REGION_AVERAGE: 'Region Average',
          AVERAGE_OVERALL: 'Average Overall',
          RELEASED_MONTHS: 'Released Months',
          ORDER: 'Order',
          DEACTIVE_A: 'Deactive',
          ACTIVE_A: 'Active',
          STATUS_HISTORIC: 'Status Historic',
          STATUS: 'Status',
        },
        CHARTS: {
          TITLES: {
            BENCHMARKING_MONTHLY: 'Monthly Benchmarking',
            AVERAGE_FERMENTEC_CUSTOMERS: 'Average Fermentec Customers',
          },
        },
        LINKS: {
          GO_TO_PROFILE_EDIT: 'Go to profile edit',
        },
      },
    },
  },
  react: {
    wait: true,
    defaultTransParent: 'div',
  },
})

export default i18n
