import React from 'react'
import { call, put, select } from 'redux-saga/effects'
import axios from 'axios'

// eslint-disable-next-line
import api from 'services/api'

import { transformArrayToObjs } from 'helpers/stateShapeUtils'
import Translator from 'components/Translator'
import { Types } from '../ducks/reportsWeekly'
import { Types as ToastTypes, Creators as ToastsActions } from '../ducks/toasts'
import { getReportsWeeklyAreas, getCurrentUser as Profile } from './selectors'

export function* ReportsWeeklyFetchAllRegionsRequest(_action) {
  try {
    const response = yield call(api.regions().getAll)

    const normalizedData = {
      byUuid: transformArrayToObjs(response.data),
      allUuids: Object.keys(transformArrayToObjs(response.data)),
    }

    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_REGIONS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_REGIONS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyFetchAllUnitsRequest(action) {
  try {
    const response = yield call(api.unitsOfRegion().getAll, {
      regionUuid: action.regionUuid,
    })

    const responses = yield call(
      axios.all,
      response.data.map(unit => api.units().getOne({ uuid: unit.unit_uuid }))
    )

    const units = responses.map(elem => elem.data)

    const profile = yield select(Profile)

    if (profile.specialist) {
      const { data: teams } = yield call(api.teams().getAll)

      const filteredTeams = teams.filter(
        team => team.users.filter(user => user.uuid === profile.uuid).length > 0
      )

      const payload = units
        .map(unit => ({
          ...unit,
          team: filteredTeams.find(
            team => team.units.filter(u => u.uuid === unit.uuid).length > 0
          ),
        }))
        .map(unit => ({
          ...unit,
          color: unit.team ? unit.team.color : null,
        }))

      const normalizedData = {
        byUuid: transformArrayToObjs(payload, 'uuid'),
        allUuids: Object.keys(transformArrayToObjs(payload, 'uuid')),
      }
      yield put({
        type: Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_SUCCESS,
        payload: {
          data: normalizedData,
        },
      })
    } else {
      const normalizedData = {
        byUuid: transformArrayToObjs(units, 'uuid'),
        allUuids: Object.keys(transformArrayToObjs(units, 'uuid')),
      }

      yield put({
        type: Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_SUCCESS,
        payload: {
          data: normalizedData,
        },
      })
    }
  } catch (err) {
    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyFetchAllTagsRequest(action) {
  try {
    const response = yield call(api.widgets().update, {
      uuid: action.widgetUuid,
      toUpdate: { datasources: action.tags },
    })

    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_TAGS_SUCCESS,
      payload: {
        data: response.data.widget,
        widgetUuid: action.widgetUuid,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_TAGS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyFetchAllAreasRequest(action) {
  try {
    const response = yield call(api.boards().getAllFromUnit, {
      unitUuid: action.unitUuid,
    })

    const weekBoards = response.data.filter(
      board => board.description === 'week'
    )

    const normalizedData = {
      byUuid: transformArrayToObjs(weekBoards),
      allUuids: weekBoards.map(elem => elem.uuid),
    }

    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_AREAS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_ALL_AREAS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyAddAreaRequest(action) {
  try {
    const response = yield call(api.boards().create, {
      ...action.values,
      description: 'week',
      unit_uuid: action.currentUnit,
    })

    const responseWidget = yield call(api.widgets().create, {
      description: `widget ${action.values.name}`,
      type: 'text',
      board_uuid: response.data.data.uuid,
    })

    const widgets = {
      byUuid: {
        [responseWidget.data.widget.uuid]: {
          ...responseWidget.data.widget,
          datasources: [],
        },
      },
      allUuids: [responseWidget.data.widget.uuid],
    }

    const normalizedData = {
      byUuid: {
        [response.data.data.uuid]: {
          ...response.data.data,
          widgets: [responseWidget.data.widget.uuid],
        },
      },
      allUuids: [response.data.data.uuid],
    }

    yield put({
      type: Types.REPORTS_WEEKLY_ADD_AREA_SUCCESS,
      payload: {
        data: normalizedData,
        widgets,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_WEEKLY_ADD_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyAddMultiAreasRequest(action) {
  try {
    for (let index = 0; index < action.values.length; index += 1) {
      const device = action.values[index]
      const response = yield call(api.boards().create, {
        ...action.device,
        name: device.name,
        description: 'week',
        unit_uuid: action.currentUnit,
      })

      const responseWidget = yield call(api.widgets().create, {
        description: `widget ${device.name}`,
        type: 'text',
        board_uuid: response.data.data.uuid,
        datasources: device.tags.map(elem => ({
          tag_uuid: elem.uuid,
          description: elem.name,
        })),
      })

      const widgets = {
        byUuid: {
          [responseWidget.data.widget.uuid]: {
            ...responseWidget.data.widget,
            datasources: [],
          },
        },
        allUuids: [responseWidget.data.widget.uuid],
      }

      const normalizedData = {
        byUuid: {
          [response.data.data.uuid]: {
            ...response.data.data,
            widgets: [responseWidget.data.widget.uuid],
          },
        },
        allUuids: [response.data.data.uuid],
      }

      yield put({
        type: Types.REPORTS_WEEKLY_ADD_AREA_SUCCESS,
        payload: {
          data: normalizedData,
          widgets,
        },
      })
    }
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: Types.REPORTS_WEEKLY_ADD_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyRemoveAreaRequest(action) {
  try {
    yield call(api.boards().delete, {
      uuid: action.uuid,
    })

    yield put({
      type: Types.REPORTS_WEEKLY_REMOVE_AREA_SUCCESS,
      payload: {
        uuid: action.uuid,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_WEEKLY_REMOVE_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyAddWidgetRequest(action) {
  try {
    const {
      data: { widget },
    } = yield call(api.widgets().create, {
      description: `widget ${action.values.name}`,
      type: action.values.type,
      board_uuid: action.boardUuid,
    })

    const normalizedData = {
      byUuid: {
        [widget.uuid]: { ...widget, datasources: [] },
      },
      allUuids: [widget.uuid],
    }

    const areas = yield select(getReportsWeeklyAreas)

    const normalizedDataAreas = {
      byUuid: {
        [action.boardUuid]: {
          ...areas.byUuid[action.boardUuid],
          widgets: [...areas.byUuid[action.boardUuid].widgets, widget.uuid],
        },
      },
    }

    yield put({
      type: Types.REPORTS_WEEKLY_ADD_WIDGET_SUCCESS,
      payload: {
        widgets: normalizedData,
        areas: normalizedDataAreas,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_WEEKLY_ADD_WIDGET_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyRemoveWidgetRequest(action) {
  try {
    yield call(api.widgets().delete, {
      uuid: action.uuid,
    })

    yield put({
      type: Types.REPORTS_WEEKLY_REMOVE_WIDGET_SUCCESS,
      payload: {
        uuid: action.uuid,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_WEEKLY_REMOVE_WIDGET_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsWeeklyFetchWidgetByAreaRequest(action) {
  try {
    const response = yield call(api.widgets().getAllFromBoard, {
      boardUuid: action.areaUuid,
    })
    // debugger
    const normalizedData = {
      byUuid: transformArrayToObjs(response.data),
      allUuids: response.data.map(elem => elem.uuid),
    }

    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_SUCCESS,
      payload: {
        data: normalizedData,
        areaUuid: action.areaUuid,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
