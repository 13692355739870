import { createActions, createReducer } from 'reduxsauce'
import { removeByUuid } from 'helpers/stateShapeUtils'

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  ReportsMonthlyFetchAllRegionsRequest: [],
  ReportsMonthlyFetchAllRegionsSuccess: [],
  ReportsMonthlyFetchAllRegionsFailure: [],

  ReportsMonthlyFetchAllUnitsRequest: ['regionUuid'],
  ReportsMonthlyFetchAllUnitsSuccess: [],
  ReportsMonthlyFetchAllUnitsFailure: [],

  ReportsMonthlyFetchAllTagsRequest: ['widgetUuid', 'tags'],
  ReportsMonthlyFetchAllTagsSuccess: [],
  ReportsMonthlyFetchAllTagsFailure: [],

  ReportsMonthlyFetchAllAreasRequest: ['unitUuid'],
  ReportsMonthlyFetchAllAreasSuccess: [],
  ReportsMonthlyFetchAllAreasFailure: [],

  ReportsMonthlyAddAreaRequest: ['values', 'currentUnit'],
  ReportsMonthlyAddAreaSuccess: [],
  ReportsMonthlyAddAreaFailure: [],

  ReportsMonthlyAddMultiAreasRequest: ['values', 'currentUnit'],
  ReportsMonthlyAddMultiAreasSuccess: [],
  ReportsMonthlyAddMultiAreasFailure: [],

  ReportsMonthlyRemoveAreaRequest: ['uuid'],
  ReportsMonthlyRemoveAreaSuccess: [],
  ReportsMonthlyRemoveAreaFailure: [],

  ReportsMonthlyFetchAllWidgetRequest: [
    'unitUuid',
    'setPercentage',
    'finishOperation',
  ],
  ReportsMonthlyFetchAllWidgetSuccess: [],
  ReportsMonthlyFetchAllWidgetFailure: [],

  ReportsMonthlyFetchWidgetByAreaRequest: ['areaUuid', 'finishOperation'],
  ReportsMonthlyFetchWidgetByAreaSuccess: [],
  ReportsMonthlyFetchWidgetByAreaFailure: [],

  ReportsMonthlyChangeTagsFilter: ['value'],
  ReportsMonthlyChangeRegionFilter: ['value'],
  ReportsMonthlyChangePeriodFilter: ['value'],

  ReportsMonthlyFetchDataRequest: [],
  ReportsMonthlyFetchDataSuccess: [],
  ReportsMonthlyFetchDataFailure: [],

  ReportsMonthlyToggleDisplayNames: [],
  ReportsMonthlyToggleDisplayLabels: [],

  ReportsMonthlyResetState: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  displayNames: false,
  displayLabels: false,
  devices: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  areas: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  widgets: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  regions: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  units: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  filter: {
    tags: null,
    period: undefined,
  },
}

const resetState = (_state = INITIAL_STATE, _action) => INITIAL_STATE

/* Fetch All Regions */
const fetchAllRegionsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  regions: { ...state.regions, loading: true, error: null },
})

const fetchAllRegionsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: {
    ...state.regions,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllRegionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: { ...state.regions, loading: false, error: action.payload },
})

/* Fetch All Units */
const fetchAllUnitsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  units: { ...state.units, loading: true, error: null },
})

const fetchAllUnitsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  units: {
    ...state.units,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllUnitsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  units: { ...state.units, loading: false, error: action.payload },
})

const fetchAllTagsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: { ...state.widgets, loading: true, error: null },
})

const fetchAllTagsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    ...action.payload.data,
    byUuid: {
      ...state.widgets.byUuid,
      [action.payload.widgetUuid]: {
        ...state.widgets.byUuid[action.payload.widgetUuid],
        ...action.payload.data,
      },
    },
    loading: false,
  },
})

const fetchAllTagsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: { ...state.widgets, loading: false, error: action.payload },
})

/* Fetch All Areas */
const fetchAllAreasRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const fetchAllAreasSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    ...action.payload.data,
    byUuid: action.payload.data.byUuid,
    allUuids: action.payload.data.allUuids,
    loading: false,
  },
})

const fetchAllAreasFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: { ...state.areas, loading: false, error: action.payload },
})

/* Fetch widget by Area */
const fetchWidgetByAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: { ...state.widgets, loading: true, error: null },
})

const fetchWidgetByAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      [action.payload.areaUuid]: {
        ...state.areas.byUuid[action.payload.areaUuid],
        widgets: [action.payload.data.allUuids[0]],
      },
    },
  },
  widgets: {
    ...state.widgets,
    ...action.payload.data,
    loading: false,
  },
})

const fetchWidgetByAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: { ...state.widgets, loading: false, error: action.payload },
})

const fetchAllWidgetRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: { ...state.widgets, loading: true, error: null },
})

const fetchAllWidgetSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      [action.payload.areaUuid]: {
        ...state.areas.byUuid[action.payload.areaUuid],
        widgets: [action.payload.data.allUuids[0]],
      },
    },
  },
  widgets: {
    ...state.widgets,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllWidgetFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: { ...state.widgets, loading: false, error: action.payload },
})

const addMultiAreasRequest = (state = INITIAL_STATE) => ({
  ...state,
})
const addMultiAreasSuccess = (state = INITIAL_STATE) => ({
  ...state,
})
const addMultiAreasFailure = (state = INITIAL_STATE) => ({
  ...state,
})

/* Add new Area */
const addAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const addAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      ...action.payload.data.byUuid,
    },
    allUuids: [...state.areas.allUuids, ...action.payload.data.allUuids],
    loading: false,
  },
  widgets: {
    ...state.widgets,
    byUuid: {
      ...state.widgets.byUuid,
      ...action.payload.widgets.byUuid,
    },
    allUuids: [...state.widgets.allUuids, ...action.payload.widgets.allUuids],
    loading: false,
  },
})

const addAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: { ...state.areas, loading: false, error: action.payload },
})

/* Remove Area */
const removeAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const removeAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: removeByUuid(state.areas.byUuid, action.payload.uuid),
    allUuids: state.areas.allUuids.filter(
      (elem) => elem.uuid !== action.payload.uuid
    ),
    loading: false,
  },
})

const removeAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    loading: false,
    error: action.payload,
  },
})

/* Changes */
const changeTagsFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, tags: action.value },
})

const changePeriodFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, period: action.value },
})

/* Fetch data */
const fetchDataRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  loading: true,
  error: null,
})

const fetchDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: null,
  data: action.payload.data,
})

const fetchDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
})

const toggleDisplayNames = (state = INITIAL_STATE) => ({
  ...state,
  displayNames: !state.displayNames,
})

const toggleDisplayLabels = (state = INITIAL_STATE) => ({
  ...state,
  displayLabels: !state.displayLabels,
})

/**
 * Reducers
 */
const reportsMonthly = createReducer(INITIAL_STATE, {
  [Types.REPORTS_MONTHLY_FETCH_ALL_REGIONS_REQUEST]: fetchAllRegionsRequest,
  [Types.REPORTS_MONTHLY_FETCH_ALL_REGIONS_SUCCESS]: fetchAllRegionsSuccess,
  [Types.REPORTS_MONTHLY_FETCH_ALL_REGIONS_FAILURE]: fetchAllRegionsFailure,

  [Types.REPORTS_MONTHLY_FETCH_ALL_UNITS_REQUEST]: fetchAllUnitsRequest,
  [Types.REPORTS_MONTHLY_FETCH_ALL_UNITS_SUCCESS]: fetchAllUnitsSuccess,
  [Types.REPORTS_MONTHLY_FETCH_ALL_UNITS_FAILURE]: fetchAllUnitsFailure,

  [Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_REQUEST]: fetchAllTagsRequest,
  [Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_SUCCESS]: fetchAllTagsSuccess,
  [Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_FAILURE]: fetchAllTagsFailure,

  [Types.REPORTS_MONTHLY_FETCH_ALL_AREAS_REQUEST]: fetchAllAreasRequest,
  [Types.REPORTS_MONTHLY_FETCH_ALL_AREAS_SUCCESS]: fetchAllAreasSuccess,
  [Types.REPORTS_MONTHLY_FETCH_ALL_AREAS_FAILURE]: fetchAllAreasFailure,

  [Types.REPORTS_MONTHLY_FETCH_ALL_WIDGET_REQUEST]: fetchAllWidgetRequest,
  [Types.REPORTS_MONTHLY_FETCH_ALL_WIDGET_SUCCESS]: fetchAllWidgetSuccess,
  [Types.REPORTS_MONTHLY_FETCH_ALL_WIDGET_FAILURE]: fetchAllWidgetFailure,

  [Types.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_REQUEST]: fetchWidgetByAreaRequest,
  [Types.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_SUCCESS]: fetchWidgetByAreaSuccess,
  [Types.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_FAILURE]: fetchWidgetByAreaFailure,

  [Types.REPORTS_MONTHLY_ADD_AREA_REQUEST]: addAreaRequest,
  [Types.REPORTS_MONTHLY_ADD_AREA_SUCCESS]: addAreaSuccess,
  [Types.REPORTS_MONTHLY_ADD_AREA_FAILURE]: addAreaFailure,

  [Types.REPORTS_MONTHLY_ADD_MULTI_AREAS_REQUEST]: addMultiAreasRequest,
  [Types.REPORTS_MONTHLY_ADD_MULTI_AREAS_SUCCESS]: addMultiAreasSuccess,
  [Types.REPORTS_MONTHLY_ADD_MULTI_AREAS_FAILURE]: addMultiAreasFailure,

  [Types.REPORTS_MONTHLY_REMOVE_AREA_REQUEST]: removeAreaRequest,
  [Types.REPORTS_MONTHLY_REMOVE_AREA_SUCCESS]: removeAreaSuccess,
  [Types.REPORTS_MONTHLY_REMOVE_AREA_FAILURE]: removeAreaFailure,

  [Types.REPORTS_MONTHLY_FETCH_DATA_REQUEST]: fetchDataRequest,
  [Types.REPORTS_MONTHLY_FETCH_DATA_SUCCESS]: fetchDataSuccess,
  [Types.REPORTS_MONTHLY_FETCH_DATA_FAILURE]: fetchDataFailure,

  [Types.REPORTS_MONTHLY_CHANGE_TAGS_FILTER]: changeTagsFilter,
  [Types.REPORTS_MONTHLY_CHANGE_PERIOD_FILTER]: changePeriodFilter,

  [Types.REPORTS_MONTHLY_RESET_STATE]: resetState,
  [Types.REPORTS_MONTHLY_TOGGLE_DISPLAY_NAMES]: toggleDisplayNames,
  [Types.REPORTS_MONTHLY_TOGGLE_DISPLAY_LABELS]: toggleDisplayLabels,
})

export default reportsMonthly
