import React, { Fragment, useEffect, useCallback } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import LogRocket from 'logrocket'
import { ToastContainer } from 'react-toastify'
import { Button } from 'reactstrap'
import { notification } from 'antd'
import 'antd/lib/notification/style/css'

import 'react-toastify/dist/ReactToastify.css'

import { I18nextProvider } from 'react-i18next'

import i18n from 'helpers/i18n'

import { Store, persistor } from 'store'

import GlobalStyle from 'styles/global'
import useCacheBuster from 'hooks/useCacheBuster'

import AllRoutes from 'routes/'

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('owdg9d/cloud-qiflf')
} else {
  require('dotenv').config()
}

const App = () => {
  const cacheBuster = useCacheBuster()

  const checkVersion = useCallback(
    () => {
      const key = 'Service-worker only one notification cache buster'
      const btn = (
        <Button color="primary"
          onClick={() => {
            cacheBuster.refreshCacheAndReload();
          }}
          size="sm"
        >Atualizar</Button>
      )

      if (!cacheBuster.loading && !cacheBuster.isLatestVersion) {
        notification.open({
          message: 'Atualização Disponível',
          description: 'Uma nova atualização foi encontrada, clique em atualizar para utilizar a nova versão.',
          btn,
          key,
          duration: 0,
        })
      }
    },
    [cacheBuster],
  )

  useEffect(() => {
    checkVersion()
  }, [checkVersion])

  return (
    <Fragment>
      <GlobalStyle />
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <AllRoutes />
          </I18nextProvider>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </Fragment>
  )
}

export default App
