import { flattenDeep } from 'lodash'

export const getCurrentUnit = state => state.units.currentUnit

export const getAllTagPoints = state => state.tagPoints.data

export const getBenchmarkingFilterValues = state => state.benchmarking.filter

export const getBenchmarkingAttributes = state => state.benchmarking.attributes

export const getReportsWeeklyAreas = state => state.reportsWeekly.areas

export const getDevicesDataTags = state =>
  state.devices.data
    ? flattenDeep(state.devices.data.map(device => device.tags))
    : []

export const getCurrentUser = state => state.profile

export const getUnitsRequest = state => state.units.data
