import { call, put, select } from 'redux-saga/effects'
import flattenDepth from 'lodash/flattenDepth'
import uniqBy from 'lodash/uniqBy'

// eslint-disable-next-line
import axios from 'axios'
import api from 'services/api'

import moment from 'helpers/moment'
import { transformArrayToObjs } from 'helpers/stateShapeUtils'
import { Types } from '../ducks/benchmarking'
import { getBenchmarkingFilterValues } from './selectors'

export function* BenchmarkingFetchAllTagsRequest(_action) {
  try {
    const response = yield api.devices().getAll({
      include: 'tag',
    })

    const uniqueElementsBy = (arr, fn) =>
      arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v)
        return acc
      }, [])

    const compareNames = (a, b) => a.name === b.name

    // Criar lista com devices com todos os tags possiveis para o nome do device
    const devicesWithAllTagsByDeviceName = response.data.map(
      (device, index, arr) => {
        const devicesSameName = arr.filter((item) => item.name === device.name)
        const mergedDevice = {
          ...device,
          tags: uniqueElementsBy(
            flattenDepth(
              [...device.tags, ...devicesSameName.map((elem) => elem.tags)],
              2
            ),
            compareNames
          ),
        }
        return mergedDevice
      }
    )

    // Criar lista com devices unicos por nome
    const newArray = uniqueElementsBy(
      devicesWithAllTagsByDeviceName,
      compareNames
    )

    const devicesWithTags = newArray.map((device) => ({
      ...device,
      tags: {
        byUuid: transformArrayToObjs(device.tags),
        allUuids: Object.keys(transformArrayToObjs(device.tags)),
      },
    }))

    const normalizedData = {
      byUuid: transformArrayToObjs(devicesWithTags),
      allUuids: Object.keys(transformArrayToObjs(devicesWithTags)),
    }

    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_TAGS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_TAGS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* BenchmarkingFetchAllRegionsRequest(_action) {
  try {
    const response = yield call(api.regions().getAll)

    const requests = response.data.map((e) =>
      api.unitsOfRegion().getAll({ regionUuid: e.uuid })
    )

    const responseAttributes = yield call(axios.all, requests)

    const data = response.data.map((e, index) => ({
      ...e,
      units: responseAttributes[index].data,
    }))

    const normalizedData = {
      byUuid: transformArrayToObjs(data),
      allUuids: Object.keys(transformArrayToObjs(data)),
    }

    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_REGIONS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_REGIONS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* BenchmarkingFetchAllAttributesRequest(_action) {
  try {
    const response = yield call(api.attributes().getAll)

    const dataUnique = uniqBy(response.data, 'name')

    const normalizedData = {
      byUuid: transformArrayToObjs(dataUnique),
      allUuids: Object.keys(transformArrayToObjs(dataUnique)),
    }

    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_ATTRIBUTES_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.BENCHMARKING_FETCH_ALL_ATTRIBUTES_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* BenchmarkingFetchDataRequest(_action) {
  try {
    const values = yield select(getBenchmarkingFilterValues)
    // const attributes = yield select(getBenchmarkingAttributes)

    if (!values.tags) {
      yield put({
        type: Types.BENCHMARKING_FETCH_DATA_FAILURE,
        payload: 'SELECT_A_TAG',
      })
    } else {
      // Find units with selected tags
      debugger
      const allTags = yield api
        .devices('/v1/resource/all/tag')
        .getTagsByName({
          name: values.tags.name,
          start: `${moment(values.period, 'YYYY-MM')
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ss')}Z`,
          end: `${moment(values.period, 'YYYY-MM')
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ss')}Z`,
          status: ['1', '2', '3'],
          type: 'A',
          groupBy: 'month',
        })
        .then((result) => result.data)

      // Get data from units that have the selected tags
      const units = yield api
        .units()
        .getMany({ uuid: allTags.map((tag) => tag.unit) })
        .then((result) => result.data)

      // Get only units that belong to this report
      const reportUnits = units.filter(
        (unit) => unit.report === 'month' || unit.report === 'both'
      )

      // Filter tags by unit uuid
      const reportTags = allTags.filter((tag) =>
        reportUnits.map((unit) => unit.uuid).includes(tag.unit)
      )

      let responseAttributes

      if (values.attributes) {
        responseAttributes = yield call(api.attributeUnit().getAllByAttribute, {
          attributeUuid: values.attributes.uuid,
        })
      } else {
        responseAttributes = yield call(api.attributeUnit().getAll)
      }

      yield put({
        type: Types.BENCHMARKING_FETCH_DATA_SUCCESS,
        payload: {
          // data: flattenDepth(responseAttributes.map(res => res.data)),
          units: reportUnits,
          allTags: reportTags,
          attributesUnits: uniqBy(responseAttributes.data, 'uuid'),
        },
      })
    }
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.BENCHMARKING_FETCH_DATA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
