import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'

import PrivateRoute from 'routes/private'
import OnlySpecialistRoute from 'routes/onlySpecialist'

const Login = Loadable({
  loader: () => import('components/Login'),
  loading: () => <div />,
})

const Base = Loadable({
  loader: () => import('components/Base'),
  loading: () => <div />,
})

// const GetStarted = Loadable({
//   loader: () => import('components/Login/GetStarted'),
//   loading: () => <div />,
// })

const Dashboard = Loadable({
  loader: () => import('components/Dashboard/Dashboard'),
  loading: () => <div />,
})

const DashboardForm = Loadable({
  loader: () => import('components/Dashboard/DashboardForm'),
  loading: () => <div />,
})

const DashboardList = Loadable({
  loader: () => import('components/Dashboard/DashboardList'),
  loading: () => <div />,
})

const PagePoints = Loadable({
  loader: () => import('pages/points'),
  loading: () => <div />,
})

const PageGrouping = Loadable({
  loader: () => import('pages/grouping'),
  loading: () => <div />,
})

const PageCorrelation = Loadable({
  loader: () => import('pages/correlation'),
  loading: () => <div />,
})

const PageBenchmarking = Loadable({
  loader: () => import('pages/benchmarking'),
  loading: () => <div />,
})

const PageRegions = Loadable({
  loader: () => import('pages/regions'),
  loading: () => <div />,
})

const PageProfile = Loadable({
  loader: () => import('pages/profile'),
  loading: () => <div />,
})

const PageProfileEdit = Loadable({
  loader: () => import('pages/profile/edit'),
  loading: () => <div />,
})

const PageUsers = Loadable({
  loader: () => import('pages/users'),
  loading: () => <div />,
})

const PageUserProfile = Loadable({
  loader: () => import('pages/users/profile'),
  loading: () => <div />,
})

const PageUserEdit = Loadable({
  loader: () => import('pages/users/edit'),
  loading: () => <div />,
})

const PageReportsMonthly = Loadable({
  loader: () => import('pages/reportsMonthly'),
  loading: () => <div />,
})

const PageReportsWeekly = Loadable({
  loader: () => import('pages/reportsWeekly'),
  loading: () => <div />,
})

const PageImport = Loadable({
  loader: () => import('pages/import'),
  loading: () => <div />,
})

const PageReleasedMonths = Loadable({
  loader: () => import('pages/releasedMonths'),
  loading: () => <div />,
})

const PageTeams = Loadable({
  loader: () => import('pages/teams'),
  loading: () => <div />,
})

const PageCompanies = Loadable({
  loader: () => import('pages/companies'),
  loading: () => <div />,
})

const PageAttributes = Loadable({
  loader: () => import('pages/attributes'),
  loading: () => <div />,
})

const PageDevices = Loadable({
  loader: () => import('pages/devices'),
  loading: () => <div />,
})

const BaseRoutes = props => (
  <Base {...props}>
    <Switch>
      {/* Dashboard */}
      <Route exact path="/dashboards" component={DashboardList} />
      <Route exact path="/dashboards/form" component={DashboardForm} />
      <Route path="/dashboards/:id/edit" component={DashboardForm} />
      <Route path="/dashboards/:id" component={Dashboard} />

      {/* Perfil */}
      <Route exact path="/profile" component={PageProfile} />
      <Route path="/profile/edit" component={PageProfileEdit} />

      {/* Relatórios */}
      <Route path="/reports/weekly" component={PageReportsWeekly} />
      <Route path="/reports/monthly" component={PageReportsMonthly} />

      {/* Análises */}
      <Route path="/points" component={PagePoints} />
      <Route path="/grouping" component={PageGrouping} />
      <Route path="/correlation" component={PageCorrelation} />
      <Route path="/benchmarking" component={PageBenchmarking} />

      {/* Ajustes */}
      <Route exact path="/regions" component={PageRegions} />
      <Route path="/regions/:uuid" component={PageRegions} />
      <OnlySpecialistRoute exact path="/settings/users" component={PageUsers} />
      <OnlySpecialistRoute exact path="/settings/users/:id" component={PageUserProfile} />
      <OnlySpecialistRoute path="/settings/users/:id/edit" component={PageUserEdit} />
      <Route exact path="/settings/devices" component={PageDevices} />
      <Route exact path="/settings/import" component={PageImport} />
      <Route exact path="/settings/released-months" component={PageReleasedMonths} />
      <Route exact path="/settings/teams" component={PageTeams} />
      <Route exact path="/settings/companies" component={PageCompanies} />
      <Route exact path="/settings/attributes" component={PageAttributes} />
    </Switch>
  </Base>
)

const AllRoutes = () => (
  <Router>
    <Switch>
      <Route path="/auth" component={Login} />
      {/* <PrivateRoute path="/welcome/get-started" component={GetStarted} /> */}
      <PrivateRoute path="/" component={BaseRoutes} />
    </Switch>
  </Router>
)

export default AllRoutes
