export const Types = {
  FETCH_REQUEST: 'selectDevices/FETCH_REQUEST',
  FETCH_SUCCESS: 'selectDevices/FETCH_SUCCESS',
  FETCH_FAILURE: 'selectDevices/FETCH_FAILURE',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
}

export default function selectDevices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FETCH_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const Creators = {
  fetchSelectDevicesRequest: (data = {}) => ({
    type: Types.FETCH_REQUEST,
    payload: { data },
  }),
}
