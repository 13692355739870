import React from 'react'
import { call, put } from 'redux-saga/effects'

import api from 'services/api'

import Translator from 'components/Translator'
import { Types } from '../ducks/devices'
import { Creators as ToastsActions, Types as ToastTypes } from '../ducks/toasts'

export function* fetchDevicesRequest() {
  try {
    const { data: devices } = yield call(api.devices().getAll, {
      include: 'tag',
    })

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: { data: devices },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* addDeviceRequest(action) {
  try {
    const {
      data: { device },
    } = yield call(api.devices().create, action.payload.data)
    yield put({
      type: Types.ADD_SUCCESS,
      payload: { data: { ...device, tags: [] } },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    yield put({
      type: Types.ADD_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* removeDeviceRequest(action) {
  try {
    yield call(api.devices().delete, { uuid: action.payload.data })
    yield put({
      type: Types.REMOVE_SUCCESS,
      payload: { data: { uuid: action.payload.data } },
    })
  } catch (err) {
    yield put({
      type: Types.REMOVE_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
