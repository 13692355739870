import { combineReducers } from 'redux'

import auth from './auth'
import benchmarking from './benchmarking'
import companies from './companies'
import company from './company'
import correlation from './correlation'
import devices from './devices'
import grouping from './grouping'
import notes from './notes'
import profile from './profile'
import regions from './regions'
import reportsMonthly from './reportsMonthly'
import reportsWeekly from './reportsWeekly'
import selectCompanies from './selectCompanies'
import selectDevices from './selectDevices'
import selectUnits from './selectUnits'
import tagPoints from './tagPoints'
import theme from './theme'
import units from './units'

export default combineReducers({
  auth,
  benchmarking,
  companies,
  company,
  correlation,
  devices,
  grouping,
  notes,
  profile,
  regions,
  reportsMonthly,
  reportsWeekly,
  selectCompanies,
  selectDevices,
  selectUnits,
  tagPoints,
  theme,
  units,
})
