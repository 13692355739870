import React from 'react'
import { call, put, select } from 'redux-saga/effects'
import axios from 'axios'

// eslint-disable-next-line
import api from 'services/api'

import { transformArrayToObjs } from 'helpers/stateShapeUtils'
import Translator from 'components/Translator'
import {
  getCurrentUser as Profile,
  getDevicesDataTags,
} from 'store/sagas/selectors'
import { Types } from '../ducks/reportsMonthly'
import { Types as ToastTypes, Creators as ToastsActions } from '../ducks/toasts'
import calculatePercentage from 'helpers/getPercentage'

export function* ReportsMonthlyFetchAllRegionsRequest(_action) {
  try {
    const response = yield call(api.regions().getAll)

    const normalizedData = {
      byUuid: transformArrayToObjs(response.data),
      allUuids: Object.keys(transformArrayToObjs(response.data)),
    }

    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_REGIONS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_REGIONS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyFetchAllUnitsRequest(action) {
  try {
    const response = yield call(api.unitsOfRegion().getAll, {
      regionUuid: action.regionUuid,
    })

    const responses = yield call(
      axios.all,
      response.data.map((unit) => api.units().getOne({ uuid: unit.unit_uuid }))
    )

    const unts = responses.map((elem) => elem.data)

    const normalizedData = {
      byUuid: transformArrayToObjs(unts, 'uuid'),
      allUuids: Object.keys(transformArrayToObjs(unts, 'uuid')),
    }

    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_UNITS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_UNITS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyFetchAllTagsRequest(action) {
  try {
    const profile = yield select(Profile)

    if (profile.specialist) {
      const response = yield call(api.widgets().update, {
        uuid: action.widgetUuid,
        toUpdate: { datasources: action.tags },
      })

      yield put({
        type: Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_SUCCESS,
        payload: {
          data: response.data.widget,
          widgetUuid: action.widgetUuid,
        },
      })
    } else {
      yield put({
        type: Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_SUCCESS,
        payload: {
          data: { datasources: action.tags },
          widgetUuid: action.widgetUuid,
        },
      })
    }
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_TAGS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyFetchAllAreasRequest(action) {
  try {
    const response = yield call(api.boards().getAllFromUnit, {
      unitUuid: action.unitUuid,
    })

    const monthBoards = response.data.filter(
      (board) => board.description === 'month'
    )

    const normalizedData = {
      byUuid: transformArrayToObjs(monthBoards),
      allUuids: monthBoards.map((elem) => elem.uuid),
    }

    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_AREAS_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_AREAS_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyAddAreaRequest(action) {
  try {
    const response = yield call(api.boards().create, {
      ...action.values,
      description: 'month',
      unit_uuid: action.currentUnit,
    })

    const responseWidget = yield call(api.widgets().create, {
      description: `widget ${action.values.name}`,
      type: 'text',
      board_uuid: response.data.data.uuid,
    })

    const widgets = {
      byUuid: {
        [responseWidget.data.widget.uuid]: {
          ...responseWidget.data.widget,
          datasources: [],
        },
      },
      allUuids: [responseWidget.data.widget.uuid],
    }

    const normalizedData = {
      byUuid: {
        [response.data.data.uuid]: {
          ...response.data.data,
          widgets: [responseWidget.data.widget.uuid],
        },
      },
      allUuids: [response.data.data.uuid],
    }

    yield put({
      type: Types.REPORTS_MONTHLY_ADD_AREA_SUCCESS,
      payload: {
        data: normalizedData,
        widgets,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_MONTHLY_ADD_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyAddMultiAreasRequest(action) {
  try {
    for (let index = 0; index < action.values.length; index += 1) {
      const device = action.values[index]
      const response = yield call(api.boards().create, {
        ...action.device,
        name: device.name,
        order: device.order,
        description: 'month',
        unit_uuid: action.currentUnit,
      })

      const responseWidget = yield call(api.widgets().create, {
        description: `widget ${device.name}`,
        type: 'text',
        board_uuid: response.data.data.uuid,
        datasources: device.tags.map((elem) => ({
          tag_uuid: elem.uuid,
          description: elem.name,
        })),
      })

      const widgets = {
        byUuid: {
          [responseWidget.data.widget.uuid]: {
            ...responseWidget.data.widget,
            datasources: [],
          },
        },
        allUuids: [responseWidget.data.widget.uuid],
      }

      const normalizedData = {
        byUuid: {
          [response.data.data.uuid]: {
            ...response.data.data,
            widgets: [responseWidget.data.widget.uuid],
          },
        },
        allUuids: [response.data.data.uuid],
      }

      yield put({
        type: Types.REPORTS_MONTHLY_ADD_AREA_SUCCESS,
        payload: {
          data: normalizedData,
          widgets,
        },
      })
    }
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: Types.REPORTS_MONTHLY_ADD_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyRemoveAreaRequest(action) {
  try {
    yield call(api.boards().delete, {
      uuid: action.uuid,
    })

    yield put({
      type: Types.REPORTS_MONTHLY_REMOVE_AREA_SUCCESS,
      payload: {
        uuid: action.uuid,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS
      ),
    })
  } catch (err) {
    console.log(err)

    yield put({
      type: Types.REPORTS_MONTHLY_REMOVE_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyFetchWidgetByAreaRequest(action) {
  try {
    const response = yield call(api.widgets().getAllFromBoard, {
      boardUuid: action.areaUuid,
    })

    const allTags = yield select(getDevicesDataTags)

    const widgetsResourcesWithOrder = response.data.map((widget) => ({
      ...widget,
      datasources: widget.datasources.map((datasource) => ({
        ...datasource,
        order: allTags.find((tag) => tag.uuid === datasource.tag_uuid)
          ? allTags.find((tag) => tag.uuid === datasource.tag_uuid).order
          : undefined,
      })),
    }))

    const normalizedData = {
      byUuid: transformArrayToObjs(widgetsResourcesWithOrder),
      allUuids: widgetsResourcesWithOrder.map((elem) => elem.uuid),
    }

    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_SUCCESS,
      payload: {
        data: normalizedData,
        areaUuid: action.areaUuid,
      },
    })
    action.finishOperation(widgetsResourcesWithOrder.length)
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_WIDGET_BY_AREA_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* ReportsMonthlyFetchAllWidgetRequest(action) {
  try {
    const boardsResponse = yield call(api.boards().getAllFromUnit, {
      unitUuid: action.unitUuid,
    })
    const monthBoards = boardsResponse.data.filter(
      (board) => board.description === 'month'
    )

    let conclusedRequests = Array(monthBoards.length).fill(false)
    let widgets = []

    for (var index = 0; index < monthBoards.length; index++) {
      const board = monthBoards[index]
      const response = yield api
        .widgets()
        .getAllFromBoard({ boardUuid: board.uuid })
      conclusedRequests[index] = true
      action.setPercentage(
        parseFloat(
          calculatePercentage(
            conclusedRequests.filter((item) => item === true).length,
            monthBoards.length
          ).toFixed(2)
        )
      )
      if (response.data.length > 0) {
        widgets.push(response)
      }
    }

    const allTags = yield select(getDevicesDataTags)
    const widgetsResourcesWithOrder = [
      {
        uuid: 'c858fdc0-86c7-11e9-b900-ed56ee2f2992',
        type: 'text',
        description: 'widget Eficiência Industrial',
        datasources: widgets
          .map((e) => e.data[0].datasources)
          .flat()
          .map((datasource) => ({
            ...datasource,
            order: allTags.find((tag) => tag.uuid === datasource.tag_uuid)
              ? allTags.find((tag) => tag.uuid === datasource.tag_uuid).order
              : undefined,
          })),
      },
    ]

    const normalizedData = {
      byUuid: transformArrayToObjs(widgetsResourcesWithOrder),
      allUuids: widgetsResourcesWithOrder.map((elem) => elem.uuid),
    }
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_WIDGET_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
    action.finishOperation(
      normalizedData.byUuid['c858fdc0-86c7-11e9-b900-ed56ee2f2992'].datasources
        .length
    )
  } catch (err) {
    yield put({
      type: Types.REPORTS_MONTHLY_FETCH_ALL_WIDGET_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
    console.error(err)
  }
}
