import { call, put } from 'redux-saga/effects'

import api from 'services/api'

import { Types } from '../ducks/selectCompanies'

export function* fetchSelectCompaniesRequest(_action) {
  try {
    const { data: companies } = yield call(api.companies().getAll)

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        data: companies,
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
