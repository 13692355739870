import { useState, useCallback, useEffect, useMemo } from 'react';

// import { toast } from 'react-toastify';

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function CacheBuster() {
  const [data, setData] = useState({
    loading: true,
    isLatestVersion: false,
  })

  const refreshCacheAndReload = useCallback(
    () => {
      console.log('Clearing cache and hard reloading...')
      console.log('caches', caches)
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }

      // delete browser cache and hard reload
      window.location.reload(true);
    },
    [],
  )

  useEffect(() => {
    async function checkVersions() {
      const currentVersionFile = await fetch('/meta.json', {
        cache: 'force-cache',
      }).then(response => response.json())

      const newVersionFile = await fetch('/meta.json', {
        cache: 'reload',
      }).then(response => response.json())

      const currentVersion = currentVersionFile.version
      const latestVersion = newVersionFile.version

      const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)

      if (shouldForceRefresh) {
        console.log(
          `We have a new version - ${latestVersion}. Should force refresh with command CTRL+F5`
        )

        setData({
          loading: false,
          isLatestVersion: false
        })
      } else {
        console.log(
          `You already have the latest version - ${latestVersion}. No cache refresh needed.`
        )
        setData({
          loading: false,
          isLatestVersion: true
        })
      }
    }
    checkVersions()
  }, [])

  return useMemo(() => ({ ...data, refreshCacheAndReload }), [data, refreshCacheAndReload])
}

export default CacheBuster;