import axiosClient from 'helpers/axiosClient'

export function* rehydrate(action) {
  if (!!action.payload && !!action.payload.auth.token) {
    const {
      payload: {
        auth: { token },
      },
    } = yield action
    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}
