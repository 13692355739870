export default {
  themify: {
    background: '#1d2124',
    color: '#fff',
  },
  borderColor: '#343a40',
  sidebar: {
    background: '#515B67',
    navMain: {
      background: '#404954',
      color: '#e6ebf4',
      navMainHeading: {
        color: '#DAE0EC',
      },
      navMainLink: {
        classActive: {
          background: '#515B67',
          navMainLinkIcon: {
            color: '#e6ebf4',
          },
        },
        hover: {
          color: '#e6ebf4',
          background: '#515B67',
          navMainLinkIcon: {
            color: '#e6ebf4',
          },
        },
      },
    },
  },
  pageHeader: {
    background: '#080809',
    color: '#FFFFFF',
  },
  mainContainer: {
    background: '#343a40',
    color: '#FFFFFF',
  },
  pageFooter: {
    background: '#515B67',
    color: '#FFFFFF',
    colorLink: '#74b3fb',
  },
  block: {
    background: '#6c757d',
    color: '#fff',
    header: {
      background: '#495057',
      color: '#fff',
      colorNoBackground: '#fff',
    },
    content: {
      background: '#8C9499',
      color: '#fff',
      footer: {
        background: '#6C757C',
      },
    },
    themify: {
      background: '#6c757d',
      color: '#fff',
      boxShadow: 'none',
    },
    fxShadow: {
      boxShadow: '0 2px 6px #1d2124',
    },
    btnOption: {
      color: '#fff',
      hoverColor: '#fff',
    },
    dropdownMenu: {
      background: '#080809',
      color: '#fff',
      hoverBackground: '#2f3640',
      hoverColor: '#fff',
    },
    BgGdPrimary: {
      boxShadow: '0 2px 6px #06070a',
    },
    linkPopHover: {
      boxShadow: '0 0.5rem 2rem #212529',
    },
  },
  btn: {
    light: {
      background: '#495057',
      color: '#f9fafc',
      borderColor: '#343a40',
    },
  },
  navTabs: {
    background: '#484e56',
    color: '',
    item: {
      color: '#fff',
      background: '#5c636d',
      active: {
        color: '#fff',
        background: '#6c757d',
      },
    },
  },
  table: {
    color: '#e6ebf4',
    background: '#121B25',
    th: {
      color: '#eaeef5',
      background: '#121B25',
      span: {
        color: 'hsl(0, 0%, 90%)',
      },
      border: {
        color: 'hsla(212, 35%, 11%, 0.2)',
      },
    },
    tbody: {
      background: '#495057',
      color: '#eff2f8',
      active: {
        background: '#4F575E',
      },
      trSuccess: {
        background: '#495057',
      },
      trHover: {
        background: '#4F575E',
      },
    },
  },
}
