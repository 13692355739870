import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, auth, ...props }) => (
  <Route
    {...props}
    render={renderProps => (auth.token ? (
      <Component {...renderProps} />
    ) : (
      <Redirect to={{ pathname: '/auth' }} />
    ))
    }
  />
)

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
