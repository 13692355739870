import { call, put } from 'redux-saga/effects'

import api from 'services/api'

import { Types } from '../ducks/units'

export function* addUnit(action) {
  try {
    const {
      data: { unit },
    } = yield call(api.units().create, action.payload.data)

    yield put({
      type: Types.ADD_SUCCESS,
      payload: { data: { name: unit.name, uuid: unit.uuid } },
    })
  } catch (err) {
    yield put({
      type: Types.ADD_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* removeUnit(action) {
  try {
    yield call(api.units().delete, {
      uuid: action.payload.data.uuid,
    })
    yield put({
      type: Types.REMOVE_SUCCESS,
      payload: { data: { uuid: action.payload.data.uuid } },
    })
  } catch (err) {
    yield put({
      type: Types.REMOVE_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
