import * as themes from 'styles/themes'

export const Types = {
  SET_THEME: 'units/SET_THEME',
}

const INITIAL_STATE = themes.light

export default function theme(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_THEME:
      return action.payload.theme
    default:
      return state
  }
}

export const Creators = {
  setTheme: data => ({ type: Types.SET_THEME, payload: { theme: data } }),
}
