export const Types = {
  FETCH_ALL_REQUEST: 'regions/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'regions/FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'regions/FETCH_ALL_FAILURE',
  ADD_REQUEST: 'regions/ADD_REQUEST',
  ADD_SUCCESS: 'regions/ADD_SUCCESS',
  ADD_FAILURE: 'regions/ADD_FAILURE',
  REMOVE_REQUEST: 'regions/REMOVE_REQUEST',
  REMOVE_SUCCESS: 'regions/REMOVE_SUCCESS',
  REMOVE_FAILURE: 'regions/REMOVE_FAILURE',
  FETCH_UNITS_IN_REGION_REQUEST: 'regions/FETCH_UNITS_IN_REGION_REQUEST',
  FETCH_UNITS_IN_REGION_SUCCESS: 'regions/FETCH_UNITS_IN_REGION_SUCCESS',
  FETCH_UNITS_IN_REGION_FAILURE: 'regions/FETCH_UNITS_IN_REGION_FAILURE',
  ADD_UNIT_IN_REGION_REQUEST: 'regions/ADD_UNIT_IN_REGION_REQUEST',
  ADD_UNIT_IN_REGION_SUCCESS: 'regions/ADD_UNIT_IN_REGION_SUCCESS',
  ADD_UNIT_IN_REGION_FAILURE: 'regions/ADD_UNIT_IN_REGION_FAILURE',
  REMOVE_UNIT_IN_REGION_REQUEST: 'regions/REMOVE_UNIT_IN_REGION_REQUEST',
  REMOVE_UNIT_IN_REGION_SUCCESS: 'regions/REMOVE_UNIT_IN_REGION_SUCCESS',
  REMOVE_UNIT_IN_REGION_FAILURE: 'regions/REMOVE_UNIT_IN_REGION_FAILURE',
  RESET_STATE: 'regions/RESET_STATE',
}

const INITIAL_STATE = {
  data: [],
  regions: [],
  attributes: [],
  units: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
}

export default function regions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_STATE:
      return INITIAL_STATE
    case Types.FETCH_ALL_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: action.payload.data,
      }
    case Types.FETCH_ALL_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.ADD_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: [...state.regions, action.payload.data],
      }
    case Types.ADD_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.REMOVE_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: state.regions.filter(region => region.uuid !== action.payload.data.uuid),
      }
    case Types.REMOVE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.ADD_UNIT_IN_REGION_REQUEST:
      return { ...state, units: { ...state.units, loading: true, error: null } }
    case Types.ADD_UNIT_IN_REGION_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          byUuid: { ...state.units.byUuid, ...{ [action.payload.data.uuid]: action.payload.data } },
          allUuids: [...state.units.allUuids, action.payload.data.uuid],
          loading: false,
        },
      }
    case Types.ADD_UNIT_IN_REGION_FAILURE:
      return { ...state, units: { ...state.units, loading: false, error: action.payload } }
    case Types.REMOVE_UNIT_IN_REGION_REQUEST:
      return { ...state, units: { ...state.units, loading: true, error: null } }
    case Types.REMOVE_UNIT_IN_REGION_SUCCESS:
      delete state.units.byUuid[action.payload.data.uuid]

      return {
        ...state,
        units: {
          ...state.units,
          loading: false,
          byUuid: state.units.byUuid,
          allUuids: state.units.allUuids.filter(uuid => uuid !== action.payload.data.unit.uuid),
        },
      }
    case Types.REMOVE_UNIT_IN_REGION_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.FETCH_UNITS_IN_REGION_REQUEST:
      return { ...state, units: { ...state.units, loading: true } }
    case Types.FETCH_UNITS_IN_REGION_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          loading: false,
          byUuid: action.payload.data.byUuid,
          allUuids: action.payload.data.allUuids,
        },
      }
    case Types.FETCH_UNITS_IN_REGION_FAILURE:
      return { ...state, units: { ...state.units, loading: false } }
    default:
      return state
  }
}

export const Creators = {
  fetchRegionsRequest: () => ({
    type: Types.FETCH_ALL_REQUEST,
  }),
  addRegionRequest: data => ({
    type: Types.ADD_REQUEST,
    payload: { data },
  }),
  removeRegionRequest: data => ({
    type: Types.REMOVE_REQUEST,
    payload: { data },
  }),
  addUnitIntoRegionRequest: data => ({
    type: Types.ADD_UNIT_IN_REGION_REQUEST,
    payload: data,
  }),
  removeUnitIntoRegionRequest: data => ({
    type: Types.REMOVE_UNIT_IN_REGION_REQUEST,
    payload: data,
  }),
  fetchUnitsInRegionRequest: data => ({
    type: Types.FETCH_UNITS_IN_REGION_REQUEST,
    payload: data,
  }),
  resetStateRegions: () => ({
    type: Types.RESET_STATE,
  }),
}
