export const Types = {
  FETCH_REQUEST: 'correlation/FETCH_REQUEST',
  FETCH_SUCCESS: 'correlation/FETCH_SUCCESS',
  FETCH_FAILURE: 'correlation/FETCH_FAILURE',
  RESET_STATE: 'correlation/RESET_STATE',
}

const INITIAL_STATE = {
  data: {
    tagsData: [],
    residual_std_error: '',
    r_value: '',
    slope: '',
    std_err: '',
    intercept: '',
    p_value: '',
  },
  loading: false,
  error: null,
}

export default function correlation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_STATE:
      return INITIAL_STATE
    case Types.FETCH_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const Creators = {
  fetchCorrelationRequest: (data = {}) => ({
    type: Types.FETCH_REQUEST,
    payload: { data },
  }),
  resetStateCorrenlation: () => ({
    type: Types.RESET_STATE,
  }),
}
