export const Types = {
  SET_DATA: 'units/SET_DATA',
  SET_CURRENT: 'units/SET_CURRENT',
  REQUEST: 'units/REQUEST',
  SUCCESS: 'units/SUCCESS',
  FAILURE: 'units/FAILURE',
  ADD_REQUEST: 'units/ADD_REQUEST',
  ADD_SUCCESS: 'units/ADD_SUCCESS',
  ADD_FAILURE: 'units/ADD_FAILURE',
  REMOVE_REQUEST: 'units/REMOVE_REQUEST',
  REMOVE_SUCCESS: 'units/REMOVE_SUCCESS',
  REMOVE_FAILURE: 'units/REMOVE_FAILURE',
}

const INITIAL_STATE = {
  data: [],
  currentUnit: {},
  loading: false,
  error: null,
}

export default function units(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_DATA:
      return { ...state, data: action.payload.data }
    case Types.SET_CURRENT:
      return { ...state, currentUnit: action.payload.unit }
    case Types.REQUEST:
      return { ...state, loading: true }
    case Types.SUCCESS:
      return { ...state, loading: false, data: action.payload.data }
    case Types.FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.ADD_REQUEST:
      return { ...state, loading: true }
    case Types.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload.data],
      }
    case Types.ADD_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.REMOVE_REQUEST:
      return { ...state, loading: true }
    case Types.REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(unit => unit.uuid !== action.payload.data.uuid),
      }
    case Types.REMOVE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const Creators = {
  setCurrentUnit: unit => ({ type: Types.SET_CURRENT, payload: { unit } }),

  setUnitsData: data => ({ type: Types.SET_DATA, payload: { data } }),

  getUnitsRequest: data => ({ type: Types.REQUEST, payload: { data } }),

  addUnitRequest: data => ({ type: Types.ADD_REQUEST, payload: { data } }),

  removeUnitRequest: data => ({
    type: Types.REMOVE_REQUEST,
    payload: { data },
  }),
}
