import { call, put, select } from 'redux-saga/effects'
import flatten from 'lodash/flatten'

import api from 'services/api'
import { Types } from '../ducks/selectUnits'

import { getCurrentUser as Profile } from './selectors'

export function* fetchSelectUnitsRequest(action) {
  try {
    const company = action.payload.data
    const { data: units } = yield call(api.companies().getAllUnitsInCompany, {
      companyUuid: company.uuid,
    })

    const profile = yield select(Profile)

    if (profile.specialist) {
      const { data: teams } = yield call(api.teams().getAll)

      const filteredTeams = teams.filter(
        team => team.users.filter(user => user.uuid === profile.uuid).length > 0
      )

      const filteredUnits = flatten(
        filteredTeams.map(team => team.units.map(unit => unit.uuid))
      )

      const payload = [
        ...units.filter(e => filteredUnits.includes(e.uuid)),
        ...units.filter(e => !filteredUnits.includes(e.uuid)),
      ].map(unit => ({
        ...unit,
        team: filteredTeams.find(
          team => team.units.filter(u => u.uuid === unit.uuid).length > 0
        ),
      }))

      yield put({
        type: Types.FETCH_SUCCESS,
        payload: {
          data: payload.map(unit => ({
            ...unit,
            value: unit.uuid,
            label: unit.name,
            color: unit.team ? unit.team.color : null,
          })),
        },
      })
    } else {
      yield put({
        type: Types.FETCH_SUCCESS,
        payload: {
          data: units.map(unit => ({
            ...unit,
            value: unit.uuid,
            label: unit.name,
            color: null,
          })),
        },
      })
    }
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
