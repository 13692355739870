const INITIAL_STATE = [
  {
    value: '*',
    label: '* - Dificuldades com Medição de Volumes/Amostragem/Análises',
  },
  {
    value: 'RTC',
    label:
      '* - Clientes que não possuem Novas Tecnologias (Sonda Oblíqua/ Cromatografia/ Digestor Q/F)',
  },
]

export default function notes(state = INITIAL_STATE) {
  return state
}
