import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

const Translator = ({ resourceKey, t }) => t(resourceKey)

Translator.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Translator)
