import { call, put } from 'redux-saga/effects'

import api from 'services/api'

import { Types } from '../ducks/correlation'

export function* fetchCorrelationRequest(action) {
  try {
    const { data } = action.payload
    const response = yield call(api.influxdb().getCorrelationBeetwenTags, data)

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        data: { tagsData: response.data.data, ...response.data.correlation },
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
