import { call, put, all } from 'redux-saga/effects'
import LogRocket from 'logrocket'

import api from 'services/api'
import axiosClient from 'helpers/axiosClient'

import { Creators as AuthActions } from '../ducks/auth'
import { Creators as ProfileActions } from '../ducks/profile'
import { Creators as CompanyActions } from '../ducks/company'
import { Creators as UnitsActions } from '../ducks/units'

export function* login(action) {
  try {
    const {
      data: {
        token, user, company, units,
      },
    } = yield call(api.auth().login, { data: action.payload })

    if (process.env.NODE_ENV === 'production') {
      LogRocket.identify(user.uuid, {
        name: user.name,
        email: user.email,
      })
    }

    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`

    yield all([
      put(AuthActions.loginSuccess({ token })),
      put(ProfileActions.setProfileData(user)),
      put(CompanyActions.setCompanyData(company)),
      put(UnitsActions.setUnitsData(units)),
    ])
  } catch (err) {
    console.log(err)

    yield put(AuthActions.loginFailure(err.response ? err.response.data.msg : 'UNKNOWN_ERROR'))
  }
}

export function* signup(action) {
  try {
    const {
      data: {
        token, user, company, units,
      },
    } = yield call(api.auth().signup, { data: action.payload })

    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`

    yield all([
      put(AuthActions.loginSuccess({ token })),
      put(ProfileActions.setProfileData(user)),
      put(CompanyActions.setCompanyData(company)),
      put(UnitsActions.setUnitsData(units)),
    ])
  } catch (err) {
    yield put(AuthActions.signupFailure(err.response ? err.response.data.msg : 'UNKNOWN_ERROR'))
  }
}
