import { createGlobalStyle } from 'styled-components'

import 'rc-tabs/assets/index.css'
import 'rc-cascader/assets/index.css'
import 'perfect-scrollbar-react/dist/style.min.css'
import 'react-table/react-table.css'

// console.log('console no styled global')

const GlobalStyle = createGlobalStyle`
   *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html, body {
    height: 100%;
  }

  body{
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  #root {
    height: 100%;

    #page-container {
      height: 100%;
    }

    button::-moz-focus-inner {
      border: 0;
    }
  }

  .rc-tabs-ink-bar {
    z-index: 0;
  }

  .rc-cascader-menu-item-loading:after {
    content: none;
  }

  .react-select--is-disabled {
    .react-select__control {
      background-color: hsl(0,0%,95%) !important;
      border-color: hsl(0,0%,90%) !important;
    }
  }

  .react-select__control {
    background-color: hsl(0, 0%, 100%) !important;
    border-color: hsl(220, 37%, 89%) !important;
  }

  .react-select__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #212529;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }

  .Toastify__toast--success {
    background: #27ae60;
  }

  .highcharts-color-undefined.highcharts-series-1 rect {
    display: none;
  }

  .fullscreen-text {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 9999;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 100vh;
  }

  .fullscreen-panel {
    width: 300px;
    display: inline-block;
    line-height: 30px;
  }

  .print-pagebreak { page-break-before: always; }
  @media print {
    /* @page {size: A4 landscape; } */

    .main-chart-container {
      width: 1000px;
    }

    .chart {
      .highcharts-container {
        max-height: 450px;
      }
      svg {
        width: 1000px;
      }
    }

    .small-charts {
      transform: scale(0.7);
      width: 460px !important;
      .chart {
        svg {
          width: 100%;
        }
      }
    }

    .observation {
      width: 100px !important;
    }

    .highcharts-legend {
      left: 350px !important;
    }
    @page {
      size: A4 landscape;

      margin: 1mm;

      max-height:100%;
      max-width:100%
    }

    .not-show-printing {
      display: none;
    }
  }

  .form-control {
    &.ant-calendar-picker {
      input {
        border: none;
        padding: 5px;
      }
    }
  }
  .ant-calendar-month-panel-selected-cell {
    &:not(.ant-calendar-month-panel-current-cell) {
      color: #fff !important;
    }
  }

  .update-notification {
    background-color: #222;
    border-radius: 3px;
    color: #fff;
    display: block;
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 0.8em 1.2em;
    text-decoration: none;
    transition: color 0.5s ease;
    &:hover {
      color: #cecece;
    }
  }
`

export default GlobalStyle
