import axios from 'axios'
import decode from 'jwt-decode'
import moment from 'moment'
import { Store } from 'store'
import { Creators as AuthActions } from 'store/ducks/auth'

const axiosClient = axios.create({
  baseURL: 'https://api.benchmarking.fermentec.com.br/',
})

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token)
    return !moment
      .unix(decoded.exp)
      .utc()
      .isAfter(moment().utc())
  } catch (err) {
    return false
  }
}

axiosClient.interceptors.request.use(async (config) => {
  const { token } = await Store.getState().auth
  if (!token) return config

  if (!!token && isTokenExpired(token)) {
    Store.dispatch(AuthActions.logout())
  }

  return config
})

let isAlreadyFetchingAccessToken = false

axiosClient.interceptors.response.use((response) => {
  const { token } = Store.getState().auth
  if (!token) return response

  const decoded = decode(token)
  if (
    moment
      .unix(decoded.exp)
      .utc()
      .diff(moment().utc(), 'hours') <= 2
    && !isAlreadyFetchingAccessToken
  ) {
    isAlreadyFetchingAccessToken = true
    axiosClient
      .get('/v1/account/token-renew', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((responseTokenRenew) => {
        Store.dispatch(AuthActions.loginSuccess({ token: responseTokenRenew.data.token }))
        axiosClient.defaults.headers.common.Authorization = `Bearer ${
          responseTokenRenew.data.token
        }`
        isAlreadyFetchingAccessToken = false
      })
      .catch(() => {
        Store.dispatch(AuthActions.logout())
        isAlreadyFetchingAccessToken = false
      })
  }

  return response
})

export default axiosClient
