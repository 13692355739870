import { call, put } from 'redux-saga/effects'

import api from 'services/api'

import { Types } from '../ducks/selectDevices'

export function* fetchSelectDevicesRequest(action) {
  try {
    const { data: devices } = yield call(api.devices().getAll, {
      include: 'tag',
    })

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        data: devices,
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
