export const Types = {
  FETCH_ALL_COMPANIES_REQUEST: 'companies/FETCH_ALL_COMPANIES_REQUEST',
  FETCH_ALL_COMPANIES_SUCCESS: 'companies/FETCH_ALL_COMPANIES_SUCCESS',
  FETCH_ALL_COMPANIES_FAILURE: 'companies/FETCH_ALL_COMPANIES_FAILURE',
  RESET_STATE: 'companies/RESET_STATE',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
}

export default function companies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_STATE:
      return INITIAL_STATE
    case Types.FETCH_ALL_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Types.FETCH_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const Creators = {
  fetchAllCompaniesRequest: (data = {}) => ({
    type: Types.FETCH_ALL_COMPANIES_REQUEST,
    payload: { data },
  }),
  resetStateCompanies: () => ({
    type: Types.RESET_STATE,
  }),
}
