import { createActions, createReducer } from 'reduxsauce'
import moment from 'helpers/moment'

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  BenchmarkingFetchAllTagsRequest: [],
  BenchmarkingFetchAllTagsSuccess: [],
  BenchmarkingFetchAllTagsFailure: [],

  BenchmarkingFetchAllRegionsRequest: [],
  BenchmarkingFetchAllRegionsSuccess: [],
  BenchmarkingFetchAllRegionsFailure: [],

  BenchmarkingFetchAllAttributesRequest: ['regionUuid'],
  BenchmarkingFetchAllAttributesSuccess: [],
  BenchmarkingFetchAllAttributesFailure: [],

  BenchmarkingChangeTagsFilter: ['value'],
  BenchmarkingChangeRegionFilter: ['value'],
  BenchmarkingChangePeriodFilter: ['value'],
  BenchmarkingChangeAttributesFilter: ['value'],

  BenchmarkingFetchDataRequest: [],
  BenchmarkingFetchDataSuccess: [],
  BenchmarkingFetchDataFailure: [],
  BenchmarkingSetAverageOverall: [],
})

const INITIAL_STATE = {
  data: [],
  units: [],
  allTags: [],
  attributesUnits: [],
  averageOverall: null,
  loading: false,
  error: null,
  devices: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  regions: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  attributes: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  filter: {
    tags: null,
    regions: null,
    attributes: null,
    period: moment().format('YYYY-MM'),
  },
}

const fetchAllTagsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  devices: { ...state.devices, loading: true, error: null },
})

const fetchAllTagsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  devices: {
    ...state.devices,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllTagsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  devices: { ...state.devices, loading: false, error: action.payload },
})

const fetchAllRegionsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  regions: { ...state.regions, loading: true, error: null },
})

const fetchAllRegionsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: {
    ...state.regions,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllRegionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: { ...state.tags, loading: false, error: action.payload },
})

const fetchAllAttributesRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  attributes: { ...state.attributes, loading: true, error: null },
})

const fetchAllAttributesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  attributes: {
    ...state.attributes,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllAttributesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  attributes: { ...state.tags, loading: false, error: action.payload },
})

const changeTagsFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, tags: action.value },
})

const changeRegionFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, regions: action.value },
})

const changePeriodFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, period: action.value },
})

const changeAttributesFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, attributes: action.value },
})

const fetchDataRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  loading: true,
  error: null,
})

const fetchDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: null,
  data: action.payload.data,
  units: action.payload.units,
  allTags: action.payload.allTags,
  attributesUnits: action.payload.attributesUnits,
})

const fetchDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
})

const setAverageOverall = (state = INITIAL_STATE, action) => ({
  ...state,
  averageOverall: action.payload.data,
})

/**
 * Reducers
 */
const benchmarking = createReducer(INITIAL_STATE, {
  [Types.BENCHMARKING_FETCH_ALL_TAGS_REQUEST]: fetchAllTagsRequest,
  [Types.BENCHMARKING_FETCH_ALL_TAGS_SUCCESS]: fetchAllTagsSuccess,
  [Types.BENCHMARKING_FETCH_ALL_TAGS_FAILURE]: fetchAllTagsFailure,
  [Types.BENCHMARKING_FETCH_ALL_REGIONS_REQUEST]: fetchAllRegionsRequest,
  [Types.BENCHMARKING_FETCH_ALL_REGIONS_SUCCESS]: fetchAllRegionsSuccess,
  [Types.BENCHMARKING_FETCH_ALL_REGIONS_FAILURE]: fetchAllRegionsFailure,
  [Types.BENCHMARKING_FETCH_ALL_ATTRIBUTES_REQUEST]: fetchAllAttributesRequest,
  [Types.BENCHMARKING_FETCH_ALL_ATTRIBUTES_SUCCESS]: fetchAllAttributesSuccess,
  [Types.BENCHMARKING_FETCH_ALL_ATTRIBUTES_FAILURE]: fetchAllAttributesFailure,
  [Types.BENCHMARKING_CHANGE_TAGS_FILTER]: changeTagsFilter,
  [Types.BENCHMARKING_CHANGE_REGION_FILTER]: changeRegionFilter,
  [Types.BENCHMARKING_CHANGE_PERIOD_FILTER]: changePeriodFilter,
  [Types.BENCHMARKING_CHANGE_ATTRIBUTES_FILTER]: changeAttributesFilter,
  [Types.BENCHMARKING_FETCH_DATA_REQUEST]: fetchDataRequest,
  [Types.BENCHMARKING_FETCH_DATA_SUCCESS]: fetchDataSuccess,
  [Types.BENCHMARKING_FETCH_DATA_FAILURE]: fetchDataFailure,
  [Types.BENCHMARKING_SET_AVERAGE_OVERALL]: setAverageOverall,
})

export default benchmarking
