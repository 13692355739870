import { createActions, createReducer } from 'reduxsauce'
import { removeByUuid } from 'helpers/stateShapeUtils'

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  ReportsWeeklyFetchAllRegionsRequest: [],
  ReportsWeeklyFetchAllRegionsSuccess: [],
  ReportsWeeklyFetchAllRegionsFailure: [],

  ReportsWeeklyFetchAllUnitsRequest: ['regionUuid'],
  ReportsWeeklyFetchAllUnitsSuccess: [],
  ReportsWeeklyFetchAllUnitsFailure: [],

  ReportsWeeklyFetchAllTagsRequest: ['widgetUuid', 'tags'],
  ReportsWeeklyFetchAllTagsSuccess: [],
  ReportsWeeklyFetchAllTagsFailure: [],

  ReportsWeeklyFetchAllAreasRequest: ['unitUuid'],
  ReportsWeeklyFetchAllAreasSuccess: [],
  ReportsWeeklyFetchAllAreasFailure: [],

  ReportsWeeklyAddWidgetRequest: ['boardUuid', 'values'],
  ReportsWeeklyAddWidgetSuccess: [],
  ReportsWeeklyAddWidgetFailure: [],

  ReportsWeeklyUpdateWidgetRequest: ['uuid'],
  ReportsWeeklyUpdateWidgetSuccess: [],
  ReportsWeeklyUpdateWidgetFailure: [],

  ReportsWeeklyRemoveWidgetRequest: ['uuid'],
  ReportsWeeklyRemoveWidgetSuccess: [],
  ReportsWeeklyRemoveWidgetFailure: [],

  ReportsWeeklyAddAreaRequest: ['values', 'currentUnit'],
  ReportsWeeklyAddAreaSuccess: [],
  ReportsWeeklyAddAreaFailure: [],

  ReportsWeeklyAddMultiAreasRequest: ['values', 'currentUnit'],
  ReportsWeeklyAddMultiAreasSuccess: [],
  ReportsWeeklyAddMultiAreasFailure: [],

  ReportsWeeklyRemoveAreaRequest: ['uuid'],
  ReportsWeeklyRemoveAreaSuccess: [],
  ReportsWeeklyRemoveAreaFailure: [],

  ReportsWeeklyFetchWidgetByAreaRequest: ['areaUuid'],
  ReportsWeeklyFetchWidgetByAreaSuccess: [],
  ReportsWeeklyFetchWidgetByAreaFailure: [],

  ReportsWeeklyChangeTagsFilter: ['value'],
  ReportsWeeklyChangeRegionFilter: ['value'],
  ReportsWeeklyChangePeriodFilter: ['value'],

  ReportsWeeklyFetchDataRequest: [],
  ReportsWeeklyFetchDataSuccess: [],
  ReportsWeeklyFetchDataFailure: [],

  ReportsWeeklyResetState: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  devices: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  areas: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  widgets: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  regions: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  units: {
    byUuid: {},
    allUuids: [],
    loading: false,
    error: null,
  },
  filter: {
    tags: null,
    period: undefined,
  },
}

const resetState = (_state = INITIAL_STATE, _action) => INITIAL_STATE

/* Fetch All Regions */
const fetchAllRegionsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  regions: { ...state.regions, loading: true, error: null },
})

const fetchAllRegionsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: {
    ...state.regions,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllRegionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  regions: { ...state.regions, loading: false, error: action.payload },
})

/* Fetch All Units */
const fetchAllUnitsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  units: { ...state.units, loading: true, error: null },
})

const fetchAllUnitsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  units: {
    ...state.units,
    ...action.payload.data,
    loading: false,
  },
})

const fetchAllUnitsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  units: { ...state.units, loading: false, error: action.payload },
})

const fetchAllTagsRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: { ...state.widgets, loading: true, error: null },
})

const fetchAllTagsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    ...action.payload.data,
    byUuid: {
      ...state.widgets.byUuid,
      [action.payload.widgetUuid]: {
        ...state.widgets.byUuid[action.payload.widgetUuid],
        ...action.payload.data,
      },
    },
    loading: false,
  },
})

const fetchAllTagsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: { ...state.widgets, loading: false, error: action.payload },
})

/* Fetch All Areas */
const fetchAllAreasRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const fetchAllAreasSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    ...action.payload.data,
    byUuid: action.payload.data.byUuid,
    allUuids: action.payload.data.allUuids,
    loading: false,
  },
})

const fetchAllAreasFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: { ...state.areas, loading: false, error: action.payload },
})

/* Fetch widget by Area */
const fetchWidgetByAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: { ...state.widgets, loading: true, error: null },
})

const fetchWidgetByAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      [action.payload.areaUuid]: {
        ...state.areas.byUuid[action.payload.areaUuid],
        widgets: action.payload.data.allUuids,
      },
    },
  },
  widgets: {
    ...state.widgets,
    ...action.payload.data,
    loading: false,
  },
})

const fetchWidgetByAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: { ...state.widgets, loading: false, error: action.payload },
})

/* Add new Area */
const addAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const addAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      ...action.payload.data.byUuid,
    },
    allUuids: [...state.areas.allUuids, ...action.payload.data.allUuids],
    loading: false,
  },
  widgets: {
    ...state.widgets,
    byUuid: {
      ...state.widgets.byUuid,
      ...action.payload.widgets.byUuid,
    },
    allUuids: [...state.widgets.allUuids, ...action.payload.widgets.allUuids],
    loading: false,
  },
})

const addAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: { ...state.areas, loading: false, error: action.payload },
})

/* Remove Area */
const removeAreaRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  areas: { ...state.areas, loading: true, error: null },
})

const removeAreaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    byUuid: removeByUuid(state.areas.byUuid, action.payload.uuid),
    allUuids: state.areas.allUuids.filter(elem => elem.uuid !== action.payload.uuid),
    loading: false,
  },
})

const removeAreaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  areas: {
    ...state.areas,
    loading: false,
    error: action.payload,
  },
})

/* Changes */
const changeTagsFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, tags: action.value },
})

const changePeriodFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...state.filter, period: action.value },
})

/* Fetch data */
const fetchDataRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  loading: true,
  error: null,
})

const fetchDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: null,
  data: action.payload.data,
})

const fetchDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
})

const addMultiAreasRequest = (state = INITIAL_STATE) => ({
  ...state,
})
const addMultiAreasSuccess = (state = INITIAL_STATE) => ({
  ...state,
})
const addMultiAreasFailure = (state = INITIAL_STATE) => ({
  ...state,
})

// Add Widget
const addWidgetRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: true,
    error: null,
  },
})

const addWidgetSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    byUuid: {
      ...state.widgets.byUuid,
      ...action.payload.widgets.byUuid,
    },
    allUuids: [...state.widgets.allUuids, ...action.payload.widgets.allUuids],
    loading: false,
  },
  areas: {
    ...state.areas,
    byUuid: {
      ...state.areas.byUuid,
      ...action.payload.areas.byUuid,
    },
  },
})

const addWidgetFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: false,
    error: action.payload.error,
  },
})

// Update Widget
const updateWidgetRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: false,
    error: null,
  },
})

const updateWidgetSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    byUuid: {
      ...state.widgets.byUuid,
      ...action.payload.widgets.byUuid,
    },
    allUuids: [...state.widgets.allUuids, ...action.payload.widgets.allUuids],
    loading: false,
  },
})

const updateWidgetFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: false,
    error: action.payload.error,
  },
})

// Remove Widget
const removeWidgetRequest = (state = INITIAL_STATE, _action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: false,
    error: null,
  },
})

const removeWidgetSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    byUuid: {
      ...state.widgets.byUuid,
      ...action.payload.widgets.byUuid,
    },
    allUuids: [...state.widgets.allUuids, ...action.payload.widgets.allUuids],
    loading: false,
  },
})

const removeWidgetFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  widgets: {
    ...state.widgets,
    loading: false,
    error: action.payload.error,
  },
})

/**
 * Reducers
 */
const reportsWeekly = createReducer(INITIAL_STATE, {
  [Types.REPORTS_WEEKLY_FETCH_ALL_REGIONS_REQUEST]: fetchAllRegionsRequest,
  [Types.REPORTS_WEEKLY_FETCH_ALL_REGIONS_SUCCESS]: fetchAllRegionsSuccess,
  [Types.REPORTS_WEEKLY_FETCH_ALL_REGIONS_FAILURE]: fetchAllRegionsFailure,

  [Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_REQUEST]: fetchAllUnitsRequest,
  [Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_SUCCESS]: fetchAllUnitsSuccess,
  [Types.REPORTS_WEEKLY_FETCH_ALL_UNITS_FAILURE]: fetchAllUnitsFailure,

  [Types.REPORTS_WEEKLY_FETCH_ALL_TAGS_REQUEST]: fetchAllTagsRequest,
  [Types.REPORTS_WEEKLY_FETCH_ALL_TAGS_SUCCESS]: fetchAllTagsSuccess,
  [Types.REPORTS_WEEKLY_FETCH_ALL_TAGS_FAILURE]: fetchAllTagsFailure,

  [Types.REPORTS_WEEKLY_FETCH_ALL_AREAS_REQUEST]: fetchAllAreasRequest,
  [Types.REPORTS_WEEKLY_FETCH_ALL_AREAS_SUCCESS]: fetchAllAreasSuccess,
  [Types.REPORTS_WEEKLY_FETCH_ALL_AREAS_FAILURE]: fetchAllAreasFailure,

  [Types.REPORTS_WEEKLY_ADD_AREA_REQUEST]: addAreaRequest,
  [Types.REPORTS_WEEKLY_ADD_AREA_SUCCESS]: addAreaSuccess,
  [Types.REPORTS_WEEKLY_ADD_AREA_FAILURE]: addAreaFailure,

  [Types.REPORTS_WEEKLY_ADD_MULTI_AREAS_REQUEST]: addMultiAreasRequest,
  [Types.REPORTS_WEEKLY_ADD_MULTI_AREAS_SUCCESS]: addMultiAreasSuccess,
  [Types.REPORTS_WEEKLY_ADD_MULTI_AREAS_FAILURE]: addMultiAreasFailure,

  [Types.REPORTS_WEEKLY_REMOVE_AREA_REQUEST]: removeAreaRequest,
  [Types.REPORTS_WEEKLY_REMOVE_AREA_SUCCESS]: removeAreaSuccess,
  [Types.REPORTS_WEEKLY_REMOVE_AREA_FAILURE]: removeAreaFailure,

  [Types.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_REQUEST]: fetchWidgetByAreaRequest,
  [Types.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_SUCCESS]: fetchWidgetByAreaSuccess,
  [Types.REPORTS_WEEKLY_FETCH_WIDGET_BY_AREA_FAILURE]: fetchWidgetByAreaFailure,

  [Types.REPORTS_WEEKLY_ADD_WIDGET_REQUEST]: addWidgetRequest,
  [Types.REPORTS_WEEKLY_ADD_WIDGET_SUCCESS]: addWidgetSuccess,
  [Types.REPORTS_WEEKLY_ADD_WIDGET_FAILURE]: addWidgetFailure,

  [Types.REPORTS_WEEKLY_UPDATE_WIDGET_REQUEST]: updateWidgetRequest,
  [Types.REPORTS_WEEKLY_UPDATE_WIDGET_SUCCESS]: updateWidgetSuccess,
  [Types.REPORTS_WEEKLY_UPDATE_WIDGET_FAILURE]: updateWidgetFailure,

  [Types.REPORTS_WEEKLY_REMOVE_WIDGET_REQUEST]: removeWidgetRequest,
  [Types.REPORTS_WEEKLY_REMOVE_WIDGET_SUCCESS]: removeWidgetSuccess,
  [Types.REPORTS_WEEKLY_REMOVE_WIDGET_FAILURE]: removeWidgetFailure,

  [Types.REPORTS_WEEKLY_FETCH_DATA_REQUEST]: fetchDataRequest,
  [Types.REPORTS_WEEKLY_FETCH_DATA_SUCCESS]: fetchDataSuccess,
  [Types.REPORTS_WEEKLY_FETCH_DATA_FAILURE]: fetchDataFailure,

  [Types.REPORTS_WEEKLY_CHANGE_TAGS_FILTER]: changeTagsFilter,
  [Types.REPORTS_WEEKLY_CHANGE_PERIOD_FILTER]: changePeriodFilter,

  [Types.REPORTS_WEEKLY_RESET_STATE]: resetState,
})

export default reportsWeekly
