import { call, put, select } from 'redux-saga/effects'

import api from 'services/api'

import { getCurrentUser as Profile } from 'store/sagas/selectors'
import { Types } from '../ducks/companies'

export function* fetchAllCompaniesRequest() {
  try {
    const { data: companies } = yield call(api.companies().getAll)

    const profile = yield select(Profile)

    if (profile.specialist) {
      const { data: teams } = yield call(api.teams().getAll)

      const filteredTeams = teams.filter(
        (team) => team.users.filter((user) => user.uuid === profile.uuid).length
      )

      const payload = companies.map((company) => ({
        ...company,
        units: company.units
          .map((unit) => ({
            ...unit,
            team: filteredTeams.find(
              (team) => team.units.filter((u) => u.uuid === unit.uuid).length
            ),
          }))
          .map((unit) => ({
            ...unit,
            color: unit.team ? unit.team.color : null,
          })),
      }))

      yield put({
        type: Types.FETCH_ALL_COMPANIES_SUCCESS,
        payload: {
          data: payload,
        },
      })
    } else {
      yield put({
        type: Types.FETCH_ALL_COMPANIES_SUCCESS,
        payload: {
          data: companies.filter((company) => company.units.length),
        },
      })
    }
  } catch (err) {
    yield put({
      type: Types.FETCH_ALL_COMPANIES_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
