import React from 'react'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import api from 'services/api'
import { transformArrayToObjs } from 'helpers/stateShapeUtils'

import Translator from 'components/Translator'

import { Types } from '../ducks/regions'
import { Types as ToastTypes, Creators as ToastsActions } from '../ducks/toasts'

export function* fetchAllRegionsRequest() {
  try {
    const response = yield call(api.regions().getAll)

    yield put({
      type: Types.FETCH_ALL_SUCCESS,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_ALL_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* addRegionRequest(action) {
  try {
    const { data } = action.payload
    const response = yield call(api.regions().create, data)

    yield put({
      type: Types.ADD_SUCCESS,
      payload: {
        data: response.data,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS,
      ),
    })
  } catch (err) {
    yield put({
      type: Types.ADD_UNIT_IN_REGION_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* removeRegionRequest(action) {
  try {
    const { data } = action.payload

    yield call(api.regions().delete, { uuid: data.uuid })

    yield put({
      type: Types.REMOVE_SUCCESS,
      payload: {
        data,
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS,
      ),
    })
  } catch (err) {
    yield put({
      type: Types.REMOVE_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* addUnitIntoRegionRequest(action) {
  try {
    const { regionUuid, toCreate } = action.payload

    // Request for add unit in Region
    const response = yield call(api.unitsOfRegion().create, { regionUuid, toCreate })

    // Get Unit infos
    const { data: unitData } = yield call(api.units().getOne, { uuid: response.data.unit_uuid })

    yield put({
      type: Types.ADD_UNIT_IN_REGION_SUCCESS,
      payload: {
        data: { ...unitData, ...response.data, region_unit_uuid: response.data.uuid },
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS,
      ),
    })
  } catch (err) {
    yield put({
      type: Types.ADD_UNIT_IN_REGION_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* removeUnitIntoRegionRequest(action) {
  try {
    const { regionUuid, uuid, unit } = action.payload

    yield call(api.unitsOfRegion().delete, { regionUuid, uuid })

    yield put({
      type: Types.REMOVE_UNIT_IN_REGION_SUCCESS,
      payload: {
        data: { uuid, unit },
      },
    })
    yield put({
      type: ToastTypes.SHOW_TOAST,
      toast: ToastsActions.buildToast(
        <Translator resourceKey="SUCCESS.DATA_HAS_BEEN_SAVED" />,
        ToastTypes.SUCCESS,
      ),
    })
  } catch (err) {
    yield put({
      type: Types.REMOVE_UNIT_IN_REGION_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* fetchUnitsInRegionRequest(action) {
  try {
    const { regionUuid } = action.payload
    // Get all units in region
    const { data: responseUnitsRegion } = yield call(api.unitsOfRegion().getAll, { regionUuid })

    // Create units infos requests
    const requests = responseUnitsRegion.map(unit => api.units().getOne({ uuid: unit.unit_uuid }))

    // Get infos for units
    const responsesUnits = yield call(axios.all, requests)

    // Extract data for responses
    const unitsData = responsesUnits.map(response => response.data)

    // New array with merge units data
    const newArr = responseUnitsRegion.map(u => ({
      ...unitsData.find(x => x.uuid === u.unit_uuid),
      ...u,
      region_unit_uuid: u.uuid,
    }))

    // Normalizing state shape for redux
    const normalizedData = {
      byUuid: transformArrayToObjs(newArr),
      allUuids: Object.keys(transformArrayToObjs(newArr)),
    }

    yield put({
      type: Types.FETCH_UNITS_IN_REGION_SUCCESS,
      payload: {
        data: normalizedData,
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_UNITS_IN_REGION_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}
