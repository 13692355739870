export const Types = {
  FETCH_REQUEST: 'grouping/FETCH_REQUEST',
  FETCH_SUCCESS: 'grouping/FETCH_SUCCESS',
  FETCH_FAILURE: 'grouping/FETCH_FAILURE',
  RESET_STATE: 'grouping/RESET_STATE',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
}

export default function grouping(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_STATE:
      return INITIAL_STATE
    case Types.FETCH_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const Creators = {
  fetchGroupingRequest: (data = {}) => ({
    type: Types.FETCH_REQUEST,
    payload: { data },
  }),
  resetStateGrouping: () => ({
    type: Types.RESET_STATE,
  }),
}
