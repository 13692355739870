import { call, put, select } from 'redux-saga/effects'

import api from 'services/api'

import { getAllTagPoints } from 'store/sagas/selectors'
import moment from 'helpers/moment'
import { Types } from '../ducks/tagPoints'

export function* fetchTagPointsRequest(action) {
  try {
    const { data } = action.payload
    const response = yield call(api.influxdb().getDataManyTags, {
      start: data.start,
      end: data.end,
      order: data.order,
      uuid: data.uuid,
      type: data.type,
    })

    const responseNotes = yield call(api.notes().getWithTagAndDatetime, {
      tagUuid: data.uuid,
      unitUuid: data.unit,
      datetime: [data.start, data.end],
    })

    const tagValues = response.data.map(elem => ({
      ...elem,
      points: elem.points.map((point) => {
        const findedNote = responseNotes.data.find(note => note.point_timestamp === point.time) || {}
        return {
          ...point,
          noteUuid: findedNote.uuid,
          note: findedNote.note,
          edit: false,
          loading: false,
        }
      }),
    }))

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        data: tagValues,
      },
    })
  } catch (err) {
    yield put({
      type: Types.FETCH_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* updatePointRequest(action) {
  try {
    const { data } = action.payload

    const response = yield call(api.influxdb().sendTagData, data)

    if (response.data.success) {
      const tagPoints = yield select(getAllTagPoints)

      const changedTags = tagPoints.map(tag => ({
        ...tag,
        points: tag.points.map((tagPoint) => {
          const searchedPoint = data.find(
            x => x.tag === tagPoint.tagCode
              && x.unit === tagPoint.unit
              && moment(tagPoint.time, 'YYYY-MM-DDTHH:mm:ss')
                .startOf('day')
                .isSame(moment(x.date, 'YYYY-MM-DD HH:mm:ss').startOf('day')),
          )

          if (searchedPoint) {
            return {
              time: tagPoint.time,
              inputMode: searchedPoint.inputMode,
              company: searchedPoint.client,
              status: searchedPoint.status,
              tagCode: searchedPoint.tag,
              unit: searchedPoint.unit,
              value: parseFloat(searchedPoint.value),
            }
          }
          return tagPoint
        }),
      }))

      yield put({
        type: Types.UPDATE_POINT_SUCCESS,
        payload: {
          data: changedTags,
        },
      })
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: Types.UPDATE_POINT_FAILURE,
      payload: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
    })
  }
}

export function* savePointNoteRequest(action) {
  try {
    const { point } = action.payload
    if (point.noteUuid && point.noteUuid.length) {
      yield call(api.notes().delete, { noteUuid: action.payload.point.noteUuid })

      yield put({
        type: Types.REMOVE_POINT_NOTE_SUCCESS,
        payload: {
          ...action.payload,
          point: {
            ...action.payload.point,
            noteUuid: null,
            note: null,
          },
        },
      })
    } else {
      const data = {
        tag_uuid: point.tagCode,
        unit_uuid: point.unit,
        point_timestamp: point.time,
        note: point.note,
      }

      const response = yield call(api.notes().create, data)

      const dataUpdatePoint = {
        value: point.value.toString(),
        date: moment.utc(point.time).format('YYYY-MM-DD HH:mm:ss'),
        client: point.company,
        unit: point.unit,
        tag: point.tagCode,
        inputMode: point.inputMode,
        lot: '',
        status: point.status,
      }

      yield call(api.influxdb().sendTagData, [dataUpdatePoint])

      yield put({
        type: Types.SAVE_POINT_NOTE_SUCCESS,
        payload: {
          ...action.payload,
          point: { ...action.payload.point, noteUuid: response.data.uuid },
        },
      })
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: Types.SAVE_POINT_NOTE_FAILURE,
      payload: {
        ...action.payload,
        point: {
          ...action.payload.point,
          error: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
        },
      },
    })
  }
}

export function* savePointValueRequest(action) {
  try {
    const { point } = action.payload

    const dataUpdatePoint = {
      value: point.value.toString(),
      date: moment.utc(point.time).format('YYYY-MM-DD HH:mm:ss'),
      client: point.company,
      unit: point.unit,
      tag: point.tagCode,
      inputMode: point.inputMode,
      lot: '',
      status: point.status,
    }

    yield call(api.influxdb().sendTagData, [dataUpdatePoint])
    yield put({
      type: Types.SAVE_POINT_VALUE_SUCCESS,
      payload: {
        ...action.payload,
        point: { ...action.payload.point },
      },
    })
  } catch (err) {
    console.log(err)
    yield put({
      type: Types.SAVE_POINT_VALUE_FAILURE,
      payload: {
        ...action.payload,
        point: {
          ...action.payload.point,
          error: err.response ? err.response.data.msg : 'UNKNOWN_ERROR',
        },
      },
    })
  }
}
