import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

const OnlySpecialistRoute = ({
  component: Component, profile, location, ...props
}) => (
  <Route
    {...props}
    render={renderProps => (profile.specialist ? (
      <Component {...renderProps} />
    ) : (
      <Redirect to={location.state ? location.state.from : '/'} />
    ))
    }
  />
)

const mapStateToProps = state => ({
  profile: state.profile,
})

export default connect(mapStateToProps)(OnlySpecialistRoute)
