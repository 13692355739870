import queryString from 'query-string'
import axiosClient from 'helpers/axiosClient'

const api = {
  // Auth Endpoints
  // We can call with api.auth()
  auth(url = '/v1/account') {
    return {
      signup: ({ data }) => axiosClient.post(`${url}/signup`, data),
      login: ({ data }) => axiosClient.post(`${url}/login`, data),
    }
  },
  // User Endpoints
  // We can call with api.auth()
  user(url = '/v1/account/user') {
    return {
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getAllUnitsFromUser: ({ uuid }) => axiosClient.get(`${url}/${uuid}/unit`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Units Endpoints
  // We can call with api.units().update()
  units(url = '/v1/account/unit') {
    return {
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getMany: ({ uuid }) => axiosClient.post(`${url}/getByUuid`, { uuid }),
      getAllFromUser: ({ userUuid }) =>
        axiosClient.get(`${url}/?unit_uuid=${userUuid}`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      create: (toCreate) => axiosClient.post(url, toCreate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
      activate: ({ uuid }) => axiosClient.post(`${url}/${uuid}/activate`),
      disable: ({ uuid }) => axiosClient.post(`${url}/${uuid}/disable`),
      active: ({ uuid }) => axiosClient.get(`${url}/active/${uuid}`),
      deactive: ({ uuid }) => axiosClient.get(`${url}/deactive/${uuid}`),
      insertUser: ({ unitUuid, userUuid }) =>
        axiosClient.post(`${url}/${unitUuid}/user/${userUuid}`),
      removeUser: ({ unitUuid, userUuid }) =>
        axiosClient.delete(`${url}/${unitUuid}/user/${userUuid}`),
    }
  },
  // Board Endpoints
  // We can call with api.boards().getAllFromUnit()
  boards(url = '/v1/dashboard/board') {
    return {
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getAllFromUnit: ({ unitUuid }) =>
        axiosClient.get(`${url}/?unit_uuid=${unitUuid}`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      create: (toCreate) => axiosClient.post(url, toCreate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Widget Endpoints
  // We can call with api.widgets().getAllFromBoard()
  widgets(url = '/v1/dashboard/widget') {
    return {
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getAllFromBoard: ({ boardUuid }) =>
        axiosClient.get(`${url}/?board_uuid=${boardUuid}`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      create: (toCreate) => axiosClient.post(url, toCreate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  management(url = '/v1/resource/management') {
    return {
      createAll: ({ devices, units }) =>
        axiosClient.post(`${url}/createAll`, { devices, units }),
    }
  },
  // Device Endpoints
  // We can call with api.devices().getAllFromUnit()
  devices(url = '/v1/resource/device') {
    return {
      getOne: ({ uuid, include = null }) =>
        axiosClient.get(`${url}/${uuid}/?include=${include}`),
      getAll: ({
        start = null,
        end = null,
        status = null,
        type = null,
        groupBy = null,
        include = null,
      } = {}) =>
        axiosClient.get(
          `${url}/?${queryString.stringify({
            include,
            start,
            end,
            status,
            type,
            groupBy,
          })}`
        ),
      getTagsByName: async ({
        uuid = null,
        name = null,
        start = null,
        end = null,
        status = null,
        type = null,
        groupBy = null,
        include = null,
      } = {}) =>
        axiosClient.post(url, {
          uuid,
          name,
          include,
          start,
          end,
          status,
          type,
          groupBy,
        }),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      create: (toCreate) => axiosClient.post(url, toCreate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Tags Endpoints
  // We can call with api.tags().getAllFromDevice()
  tags(url = '/v1/resource/tag') {
    return {
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getAllFromDevice: ({ deviceUuid }) =>
        axiosClient.get(`${url}/?device_uuid=${deviceUuid}`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      create: (toCreate) => axiosClient.post(url, toCreate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Regions Endpoints
  // We can call with api.regions().getAll()
  regions(url = '/v1/regions') {
    return {
      getAll: () => axiosClient.get(url),
      create: (toCreate) => axiosClient.post(url, toCreate),
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getOneData: ({ uuid, start, end, type, status }) =>
        axiosClient.get(
          `${url}/${uuid}/data/?${queryString.stringify({
            start,
            end,
            type,
            status,
          })}`
        ),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Units of Region Endpoints
  // We can call with api.unitsOfRegion().getAll()
  unitsOfRegion(url = '/v1/regions') {
    return {
      getAll: ({ regionUuid }) => axiosClient.get(`${url}/${regionUuid}/units`),
      create: ({ regionUuid, toCreate }) =>
        axiosClient.post(`${url}/${regionUuid}/units`, toCreate),
      getOne: ({ regionUuid, uuid }) =>
        axiosClient.get(`${url}/${regionUuid}/units/${uuid}`),
      update: ({ regionUuid, uuid, toUpdate }) =>
        axiosClient.put(`${url}/${regionUuid}/units/${uuid}`, toUpdate),
      delete: ({ regionUuid, uuid }) =>
        axiosClient.delete(`${url}/${regionUuid}/units/${uuid}`),
    }
  },
  // Companies Endpoints
  // We can call with api.companies().getAll()
  companies(url = '/v1/account/company') {
    return {
      getAll: (params = { paranoid: true, all_unit_status: false }) =>
        axiosClient.get(
          `${url}?paranoid=${params.paranoid}&all_unit_status=${params.all_unit_status}`
        ),
      getOne: ({ uuid }) => axiosClient.get(`${url}/${uuid}`),
      getAllUnitsInCompany: ({ companyUuid }) =>
        axiosClient.get(`${url}/${companyUuid}/unit`),
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  // Teams Endpoints
  // We can call with api.teams().getAll()
  teams(url = '/v1/account/team') {
    return {
      getAll: () => axiosClient.get(url),
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
      insertUser: ({ teamUuid, userUuid }) =>
        axiosClient.post(`${url}/${teamUuid}/user/${userUuid}`),
      insertUnit: ({ teamUuid, unitUuid }) =>
        axiosClient.post(`${url}/${teamUuid}/unit/${unitUuid}`),
      removeUser: ({ teamUuid, userUuid }) =>
        axiosClient.delete(`${url}/${teamUuid}/user/${userUuid}`),
      removeUnit: ({ teamUuid, unitUuid }) =>
        axiosClient.delete(`${url}/${teamUuid}/unit/${unitUuid}`),
    }
  },
  // Integration Endpoints
  // We can call with api.integration().sendTagData()
  integration(url = '/v1/integration') {
    return {
      sendTagData: (data) => axiosClient.post(`${url}/fermentec/setRaw`, data),
    }
  },
  // Influxdb Endpoints to get points
  // We can call with api.influxdb().sendTagData(data)
  influxdb(url = '/v1/influxdb') {
    return {
      getDataOneTag: ({ tagUuid, start, end, order, status }) =>
        axiosClient.get(
          `${url}/points/${tagUuid}?${queryString.stringify({
            start,
            end,
            order,
            status,
          })}`
        ),
      getDataManyTags: ({
        start = 'now() - 7d',
        end = 'now()',
        limit = undefined,
        order = 'asc',
        status = [],
        uuid = [],
        type,
      }) =>
        axiosClient.post(`${url}/pointsList`, {
          start,
          end,
          limit,
          order,
          status,
          uuid,
          type,
        }),
      sendTagData: (data) => axiosClient.post(`${url}/points`, data),
      getCorrelationBeetwenTags: ({
        start = 'now() - 7d',
        end = 'now()',
        limit = undefined,
        order = undefined,
        status = [],
        uuid = [],
        groupBy = undefined,
        startWeek = undefined,
        dayStart = undefined,
        type,
      }) =>
        axiosClient.post(`${url}/correlation`, {
          start,
          end,
          limit,
          order,
          status: status.map((s) => s.toString()),
          uuid,
          groupBy,
          startWeek,
          dayStart,
          type,
        }),
      getGroupedData: ({
        start = undefined,
        end = undefined,
        limit = undefined,
        order = 'asc',
        status = [],
        groupBy = 'day',
        uuid = [],
        startWeek = undefined,
        dayStart = undefined,
        operation = undefined,
        type,
      }) =>
        axiosClient.post(`${url}/pointsGroup`, {
          start,
          end,
          limit,
          order,
          status: status.map((s) => s.toString()),
          groupBy,
          uuid,
          startWeek,
          dayStart,
          operation,
          type,
        }),
    }
  },
  // Point Notes Endpoints to get points
  // We can call with api.notes().sendTagData(data)
  notes(url = '/v1/notes') {
    return {
      getWithTagAndDatetime: ({ tagUuid = [], unitUuid = [], datetime = [] }) =>
        axiosClient.get(
          `${url}/?${queryString.stringify({
            tag_uuid: tagUuid,
            unit_uuid: unitUuid,
            time: datetime,
          })}`
        ),
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ noteUuid, toUpdate }) =>
        axiosClient.put(`${url}/${noteUuid}`, toUpdate),
      delete: ({ noteUuid }) => axiosClient.delete(`${url}/${noteUuid}`),
    }
  },
  // Unit Attributes Endpoints to get attributes
  // We can call with api.attributes().getAllByName({ name })
  attributes(url = '/v1/attributes') {
    return {
      getAll: () => axiosClient.get(url),
      create: (toCreate) => axiosClient.post(url, toCreate),
      createAtributeUnit: ({ attributeUuid, toCreate }) =>
        axiosClient.post(`${url}/${attributeUuid}/units`, toCreate),
      deleteAtributeUnit: ({ attributeUuid, uuid }) =>
        axiosClient.delete(`${url}/${attributeUuid}/units/${uuid}`),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },

  attributeOption(url = '/v1/attributesOptions') {
    return {
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },

  attributeUnit(url = '/v1/attributesUnits') {
    return {
      getAll: () => axiosClient.get(url),
      getAllByAttribute: ({ attributeUuid }) =>
        axiosClient.get(
          `${url}/?${queryString.stringify({
            attribute_uuid: attributeUuid,
          })}`
        ),
      getAllByUnit: ({ unitUuid }) =>
        axiosClient.get(
          `${url}/?${queryString.stringify({
            unit_uuid: unitUuid,
          })}`
        ),
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
  monthsReleased(url = '/v1/released-months') {
    return {
      getAll: () => axiosClient.get(url),
      create: (toCreate) => axiosClient.post(url, toCreate),
      update: ({ uuid, toUpdate }) =>
        axiosClient.put(`${url}/${uuid}`, toUpdate),
      delete: ({ uuid }) => axiosClient.delete(`${url}/${uuid}`),
    }
  },
}

export default api
