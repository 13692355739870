export const Types = {
  RESET_STATE: 'tagPoints/RESET_STATE',
  FETCH_REQUEST: 'tagPoints/FETCH_REQUEST',
  FETCH_SUCCESS: 'tagPoints/FETCH_SUCCESS',
  FETCH_FAILURE: 'tagPoints/FETCH_FAILURE',
  UPDATE_POINT_REQUEST: 'tagPoints/UPDATE_POINT_REQUEST',
  UPDATE_POINT_SUCCESS: 'tagPoints/UPDATE_POINT_SUCCESS',
  UPDATE_POINT_FAILURE: 'tagPoints/UPDATE_POINT_FAILURE',
  START_EDIT_POINT_NOTE: 'tagPoints/START_EDIT_POINT_NOTE',
  CANCEL_EDIT_POINT_NOTE: 'tagPoints/CANCEL_EDIT_POINT_NOTE',
  HANDLE_EDIT_POINT_NOTE: 'tagPoints/HANDLE_EDIT_POINT_NOTE',
  START_EDIT_POINT_VALUE: 'tagPoints/START_EDIT_POINT_VALUE',
  CANCEL_EDIT_POINT_VALUE: 'tagPoints/CANCEL_EDIT_POINT_VALUE',
  HANDLE_EDIT_POINT_VALUE: 'tagPoints/HANDLE_EDIT_POINT_VALUE',
  SAVE_POINT_NOTE_REQUEST: 'tagPoints/SAVE_POINT_NOTE_REQUEST',
  SAVE_POINT_NOTE_SUCCESS: 'tagPoints/SAVE_POINT_NOTE_SUCCESS',
  SAVE_POINT_NOTE_FAILURE: 'tagPoints/SAVE_POINT_NOTE_FAILURE',
  REMOVE_POINT_NOTE_SUCCESS: 'tagPoints/REMOVE_POINT_NOTE_SUCCESS',
  SAVE_POINT_VALUE_REQUEST: 'tagPoints/SAVE_POINT_VALUE_REQUEST',
  SAVE_POINT_VALUE_SUCCESS: 'tagPoints/SAVE_POINT_VALUE_SUCCESS',
  SAVE_POINT_VALUE_FAILURE: 'tagPoints/SAVE_POINT_VALUE_FAILURE',
  CHANGE_UNIT: 'tagPoints/CHANGE_UNIT',
  CHANGE_COMPANY: 'tagPoints/CHANGE_COMPANY',
  CHANGE_PERIOD: 'tagPoints/CHANGE_PERIOD',
  CHANGE_PERIOD_STR: 'tagPoints/CHANGE_PERIOD_STR',
  CHANGE_TAGS: 'tagPoints/CHANGE_TAGS',
  CHANGE_TYPE: 'tagPoints/CHANGE_TYPE',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  unit: null,
  company: null,
  period: null,
  periodStr: '',
  tags: null,
  type: 'S',
}

export default function tagPoints(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_STATE:
      return INITIAL_STATE
    case Types.FETCH_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.UPDATE_POINT_REQUEST:
      return { ...state, loading: true, error: null }
    case Types.UPDATE_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Types.UPDATE_POINT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case Types.START_EDIT_POINT_NOTE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, edit: true } : point)),
          }
          : tag)),
      }
    case Types.CANCEL_EDIT_POINT_NOTE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, edit: false } : point)),
          }
          : tag)),
      }
    case Types.HANDLE_EDIT_POINT_NOTE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? { ...point, note: action.payload.value }
              : point)),
          }
          : tag)),
      }
    case Types.START_EDIT_POINT_VALUE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, editValue: true } : point)),
          }
          : tag)),
      }
    case Types.CANCEL_EDIT_POINT_VALUE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, editValue: false } : point)),
          }
          : tag)),
      }
    case Types.HANDLE_EDIT_POINT_VALUE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? { ...point, value: action.payload.value }
              : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_NOTE_REQUEST:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, loading: true } : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_NOTE_SUCCESS:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? {
                ...point,
                loading: false,
                edit: false,
              }
              : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_NOTE_FAILURE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time ? { ...point, loading: false } : point)),
          }
          : tag)),
      }
    case Types.REMOVE_POINT_NOTE_SUCCESS:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? {
                ...point,
                loading: false,
                edit: false,
                status: action.payload.point.status,
              }
              : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_VALUE_REQUEST:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? { ...point, loadingValue: true }
              : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_VALUE_SUCCESS:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? {
                ...point,
                loadingValue: false,
                editValue: false,
                status: 2,
              }
              : point)),
          }
          : tag)),
      }
    case Types.SAVE_POINT_VALUE_FAILURE:
      return {
        ...state,
        data: state.data.map(tag => (tag.uuid === action.payload.tagUuid
          ? {
            ...tag,
            points: tag.points.map(point => (point.time === action.payload.point.time
              ? { ...point, loadingValue: false }
              : point)),
          }
          : tag)),
      }
    case Types.CHANGE_UNIT:
      return {
        ...state,
        unit: action.payload.unit,
      }
    case Types.CHANGE_COMPANY:
      return {
        ...state,
        company: action.payload.company,
      }
    case Types.CHANGE_PERIOD:
      return {
        ...state,
        period: action.payload.period,
      }
    case Types.CHANGE_PERIOD_STR:
      return {
        ...state,
        periodStr: action.payload.periodStr,
      }
    case Types.CHANGE_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
      }
    case Types.CHANGE_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }
    default:
      return state
  }
}

export const Creators = {
  fetchTagPointsRequest: (data = {}) => ({
    type: Types.FETCH_REQUEST,
    payload: { data },
  }),
  updatePointRequest: (data = {}) => ({
    type: Types.UPDATE_POINT_REQUEST,
    payload: { data },
  }),
  resetStateTagPoints: () => ({
    type: Types.RESET_STATE,
  }),
  startEditPointNote: (point, tagUuid) => ({
    type: Types.START_EDIT_POINT_NOTE,
    payload: { point, tagUuid },
  }),
  cancelEditPointNote: (point, tagUuid) => ({
    type: Types.CANCEL_EDIT_POINT_NOTE,
    payload: { point, tagUuid },
  }),
  handleEditPointNote: (value, point, tagUuid) => ({
    type: Types.HANDLE_EDIT_POINT_NOTE,
    payload: { value, point, tagUuid },
  }),
  startEditPointValue: (point, tagUuid) => ({
    type: Types.START_EDIT_POINT_VALUE,
    payload: { point, tagUuid },
  }),
  cancelEditPointValue: (point, tagUuid) => ({
    type: Types.CANCEL_EDIT_POINT_VALUE,
    payload: { point, tagUuid },
  }),
  handleEditPointValue: (value, point, tagUuid) => ({
    type: Types.HANDLE_EDIT_POINT_VALUE,
    payload: { value, point, tagUuid },
  }),
  savePointNoteRequest: (point, tagUuid) => ({
    type: Types.SAVE_POINT_NOTE_REQUEST,
    payload: { point, tagUuid },
  }),
  savePointValueRequest: (point, tagUuid) => ({
    type: Types.SAVE_POINT_VALUE_REQUEST,
    payload: { point, tagUuid },
  }),
  changeUnit: unit => ({
    type: Types.CHANGE_UNIT,
    payload: { unit },
  }),
  changeCompany: company => ({
    type: Types.CHANGE_COMPANY,
    payload: { company },
  }),
  changePeriod: period => ({
    type: Types.CHANGE_PERIOD,
    payload: { period },
  }),
  changePeriodStr: periodStr => ({
    type: Types.CHANGE_PERIOD_STR,
    payload: { periodStr },
  }),
  changeTags: tags => ({
    type: Types.CHANGE_TAGS,
    payload: { tags },
  }),
  changeType: type => ({
    type: Types.CHANGE_TYPE,
    payload: { type },
  }),
}
